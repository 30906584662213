import { Union } from "../../../fable-library.4.1.4/Types.js";
import { union_type, bool_type, list_type, class_type, string_type } from "../../../fable-library.4.1.4/Reflection.js";
import { Common_parseOptions, Common_GenericOptions__ToReactElement_Z2B8EEFE, Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddProps_Z4E55E0BD, Color_ofColor, Common_GenericOptions__AddCaseName_1505, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection, Color_IColor_$reflection, Size_ISize_$reflection } from "../../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../../fable-library.4.1.4/MapUtil.js";
import { Reflection_getCaseName } from "../../Common.fs.js";
import { HTMLAttr } from "../../../Fable.React.9.3.0/Fable.React.Props.fs.js";
import { singleton } from "../../../fable-library.4.1.4/List.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["CustomClass", "Props", "Size", "is-focused", "is-active", "is-hovered", "is-fullwidth", "is-centered", "is-right", "is-boxed", "has-name", "is-empty", "Color", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.File.Option", [], Option, () => [[["Item", string_type]], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", Size_ISize_$reflection()]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [], [], [], [], [], [["Item", bool_type]], [["Item", Color_IColor_$reflection()]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <div class="file"></div>
 */
export function file(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        let matchResult, state;
        switch (option.tag) {
            case 6:
            case 7:
            case 8:
            case 9:
            case 10: {
                matchResult = 1;
                break;
            }
            case 12: {
                matchResult = 2;
                break;
            }
            case 1: {
                matchResult = 4;
                break;
            }
            case 0: {
                matchResult = 5;
                break;
            }
            case 13: {
                matchResult = 6;
                break;
            }
            case 3: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            case 4: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            case 5: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            case 11: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            default:
                matchResult = 0;
        }
        switch (matchResult) {
            case 0:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            case 1:
                return Common_GenericOptions__AddCaseName_1505(result, option);
            case 2:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
            case 3:
                if (state) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            case 4:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 5:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
        }
    }, "file"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

/**
 * Generate <input type="file" class="file-input"/>
 */
export function input(options) {
    return Common_GenericOptions__ToReactElement_Z2B8EEFE(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "file-input", singleton(new HTMLAttr(159, ["file"]))), (props) => react.createElement("input", keyValueList(props, 1)));
}

/**
 * Generate <span class="file-cta"></span>
 */
export function cta(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "file-cta"), (props, children_1) => react.createElement("span", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <span class="file-name"></span>
 */
export function name(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "file-name"), (props, children_1) => react.createElement("span", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <span class="file-icon"></span>
 */
export function icon(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "file-icon"), (props, children_1) => react.createElement("span", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <span class="file-label"></span>
 */
export function Label_span(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "file-label"), (props, children_1) => react.createElement("span", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <label class="file-label"></label>
 */
export function Label_label(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "file-label"), (props, children_1) => react.createElement("label", keyValueList(props, 1), ...children_1), children);
}

