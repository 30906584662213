import * as common from "../../src/javascript/common.js";
import { empty as empty_1, ofArray, map, singleton } from "../fable_modules/fable-library.4.1.4/List.js";
import { Record, Union } from "../fable_modules/fable-library.4.1.4/Types.js";
import { tuple_type, unit_type, record_type, class_type, bool_type, option_type, string_type, union_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";
import { Session__GetActiveWorkoutRecord, Icons, Session__GetCurrentName, Session__SetActiveWorkoutRecord_Z2D8A2414, Session__GetCurrentProfileId, Session_$reflection } from "../Omnicv.Client.Common/SharedView.js";
import { createObj, comparePrimitives, equals } from "../fable_modules/fable-library.4.1.4/Util.js";
import { EventInfo_$reflection } from "../Omnicv.Shared.Common/CoreEventInfo.js";
import { WorkoutRecord_$reflection } from "../Omnicv.Shared/FitnessAPI.js";
import { Cmd_none, Cmd_batch, Cmd_OfPromise_either } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { map as map_1, toList as toList_1, FSharpMap__Add, empty } from "../fable_modules/fable-library.4.1.4/Map.js";
import { value as value_67, some } from "../fable_modules/fable-library.4.1.4/Option.js";
import { Router_modifyLocation, Route, Router_newUrl } from "../Omnicv.Client.Common/Router.js";
import { op_Subtraction, compare } from "../fable_modules/fable-library.4.1.4/Date.js";
import { exnToDisplayString } from "../Omnicv.Client.Common/Utils.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { fitnessService } from "../Pages/Fitness/FitnessCommon.js";
import { commonAuthService } from "../Omnicv.Client.Common/CommonServer.js";
import { createElement } from "react";
import { Spacing_Amount, Spacing_TypeAndDirection, Modifier_IModifier, Color_IColor } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { split, format, join } from "../fable_modules/fable-library.4.1.4/String.js";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/Interop.fs.js";
import { Router_href } from "../Omnicv.Client.Common/Router.js";
import { Config_releaseVersion, Config_getDefaultDiaryDate } from "../Omnicv.Client.Common/Extensions.js";
import { List_Option, list as list_1, Option, button } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { icon } from "../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_i } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Icons__ToIcon } from "../Omnicv.Client.Common/SharedView.js";
import { empty as empty_2, append, singleton as singleton_1, delay, toList } from "../fable_modules/fable-library.4.1.4/Seq.js";
import { seconds, minutes, hours } from "../fable_modules/fable-library.4.1.4/TimeSpan.js";
import { defaultOf } from "../fable_modules/fable-library.4.1.4/Util.js";
import { Class, closeable } from "../Omnicv.Client.Common/Components/Notification.js";
import { last } from "../fable_modules/fable-library.4.1.4/Array.js";

const soundPath = "/sounds/beep-03.mp3";

const soundAudioContext = common.createAudio(soundPath);

const permissionsToCheck = singleton("geolocation");

export class LogInState extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LoggedIn", "PendingLogOut", "LoggedOut"];
    }
}

export function LogInState_$reflection() {
    return union_type("Omnicv.Client.Components.Navigation.LogInState", [], LogInState, () => [[], [], []]);
}

export class Model extends Record {
    constructor(LogInState, Message, Session, InProgress, CurrentTimestamp, CurrentTimerExpiry, CheckedPermissions) {
        super();
        this.LogInState = LogInState;
        this.Message = Message;
        this.Session = Session;
        this.InProgress = InProgress;
        this.CurrentTimestamp = CurrentTimestamp;
        this.CurrentTimerExpiry = CurrentTimerExpiry;
        this.CheckedPermissions = CheckedPermissions;
    }
}

export function Model_$reflection() {
    return record_type("Omnicv.Client.Components.Navigation.Model", [], Model, () => [["LogInState", LogInState_$reflection()], ["Message", option_type(string_type)], ["Session", Session_$reflection()], ["InProgress", bool_type], ["CurrentTimestamp", class_type("System.DateTime")], ["CurrentTimerExpiry", option_type(class_type("System.DateTime"))], ["CheckedPermissions", class_type("Microsoft.FSharp.Collections.FSharpMap`2", [string_type, class_type("Browser.Types.PermissionStatus")])]]);
}

export function Model__IsLoggedIn(this$) {
    return !equals(this$.LogInState, new LogInState(2, []));
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LogOutClicked", "LogOutSucceeded", "Failure", "Refresh", "TimerUpdate", "ConcludeActiveWorkoutRecord", "DeleteActiveWorkoutRecord", "ActiveWorkoutRecordCloseSuccess", "UpdateActiveWorkoutRecord", "StartTimer", "StopTimer", "PermissionStatus"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Components.Navigation.Msg", [], Msg, () => [[], [["Item", string_type]], [["Item", class_type("System.Exception")]], [["Item", Session_$reflection()]], [["Item", class_type("System.DateTime")]], [], [], [["Item", unit_type]], [["Item", option_type(tuple_type(EventInfo_$reflection(), WorkoutRecord_$reflection()))]], [["Item", class_type("System.DateTime")]], [], [["Item1", string_type], ["Item2", class_type("Browser.Types.PermissionStatus")]]]);
}

function queryPermission(permission) {
    const descriptor_1 = {
        name: permission,
    };
    const pr = navigator.permissions.query(descriptor_1);
    return pr.then((status) => [permission, status]);
}

function queryPermissionCmd(permission) {
    return Cmd_OfPromise_either(queryPermission, permission, (tupledArg) => (new Msg(11, [tupledArg[0], tupledArg[1]])), (arg_2) => (new Msg(2, [arg_2])));
}

export function init(session) {
    return [new Model(session.LoggedIn ? (new LogInState(0, [])) : (new LogInState(2, [])), void 0, session, false, common.getNow(), void 0, empty({
        Compare: comparePrimitives,
    })), Cmd_batch(map(queryPermissionCmd, permissionsToCheck))];
}

export function update(session, msg, model) {
    let matchValue, time;
    switch (msg.tag) {
        case 1: {
            console.log(some(`Updating session logged out, msg: ${msg.fields[0]}`));
            return [new Model(new LogInState(2, []), "Logged out successfully", model.Session, model.InProgress, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Router_newUrl(new Route(0, []))];
        }
        case 4:
            return [new Model(model.LogInState, model.Message, model.Session, model.InProgress, msg.fields[0], (matchValue = model.CurrentTimerExpiry, (matchValue != null) ? ((time = matchValue, (compare(common.getNow(), time) >= 0) ? ((soundAudioContext.play(), void 0)) : model.CurrentTimerExpiry)) : void 0), model.CheckedPermissions), Cmd_none()];
        case 9:
            return [new Model(model.LogInState, model.Message, model.Session, model.InProgress, model.CurrentTimestamp, msg.fields[0], model.CheckedPermissions), Cmd_none()];
        case 10:
            return [new Model(model.LogInState, model.Message, model.Session, model.InProgress, model.CurrentTimestamp, void 0, model.CheckedPermissions), Cmd_none()];
        case 2:
            return [new Model(new LogInState(0, []), exnToDisplayString(msg.fields[0]), model.Session, model.InProgress, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Cmd_none()];
        case 3: {
            const session_1 = msg.fields[0];
            return [new Model(session_1.LoggedIn ? (new LogInState(0, [])) : (new LogInState(2, [])), void 0, session_1, model.InProgress, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Cmd_none()];
        }
        case 5:
            return [new Model(model.LogInState, model.Message, model.Session, true, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, fitnessService.ConcludeActiveWorkoutRecord, Session__GetCurrentProfileId(session), () => (new Msg(7, [void 0])), (arg_4) => (new Msg(2, [arg_4])))];
        case 6:
            return [new Model(model.LogInState, model.Message, model.Session, true, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, fitnessService.DeleteActiveWorkoutRecord, Session__GetCurrentProfileId(session), () => (new Msg(7, [void 0])), (arg_7) => (new Msg(2, [arg_7])))];
        case 7:
            return [new Model(model.LogInState, model.Message, model.Session, false, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), singleton((dispatch) => {
                dispatch(new Msg(8, [void 0]));
            })];
        case 8:
            return [new Model(model.LogInState, model.Message, Session__SetActiveWorkoutRecord_Z2D8A2414(model.Session, msg.fields[0]), model.InProgress, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Cmd_none()];
        case 11:
            return [new Model(model.LogInState, model.Message, model.Session, model.InProgress, model.CurrentTimestamp, model.CurrentTimerExpiry, FSharpMap__Add(model.CheckedPermissions, msg.fields[0], msg.fields[1])), Cmd_none()];
        default:
            if (!equals(model.LogInState, new LogInState(0, []))) {
                return [model, Cmd_none()];
            }
            else {
                return [new Model(new LogInState(1, []), model.Message, model.Session, model.InProgress, model.CurrentTimestamp, model.CurrentTimerExpiry, model.CheckedPermissions), Cmd_OfAsyncWith_either((x) => {
                    Cmd_OfAsync_start(x);
                }, commonAuthService.Logout, void 0, (arg) => (new Msg(1, [arg])), (arg_1) => (new Msg(2, [arg_1])))];
            }
    }
}

const burgerLine = createElement("span", {
    "aria-hidden": true,
});

const mainNavBarId = "mainNavBar";

const profileColours = [new Color_IColor(5, []), new Color_IColor(6, []), new Color_IColor(7, []), new Color_IColor(4, []), new Color_IColor(16, [])];

function getProfileColour(profileId) {
    const index = (profileId % profileColours.length) | 0;
    return profileColours[index];
}

function viewMainNavBar(model, dispatch) {
    let elems_6, elems_2, elems_5, elems_3, arg, elems, name_12;
    const isLoggedIn = Model__IsLoggedIn(model);
    return createElement("nav", createObj(ofArray([["className", join(" ", ["navbar", "is-primary"])], ["role", join(" ", ["navigation"])], ["aria-label", "main navigation"], (elems_6 = [createElement("div", createObj(ofArray([["className", "navbar-brand"], (elems_2 = [createElement("a", {
        className: "navbar-item",
        children: "OmniCV",
    }), createElement("a", {
        className: "navbar-burger",
        "aria-label": "menu",
        role: join(" ", ["button"]),
        "aria-expanded": false,
        "data-target": mainNavBarId,
        children: Interop_reactApi.Children.toArray([burgerLine, burgerLine, burgerLine]),
    })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", "navbar-menu"], ["id", mainNavBarId], (elems_5 = [createElement("div", createObj(ofArray([["className", "navbar-start"], (elems_3 = (isLoggedIn ? ofArray([createElement("a", createObj(ofArray([["className", "navbar-item"], Router_href(new Route(0, [])), ["children", "Home"]]))), createElement("a", createObj(ofArray([["className", "navbar-item"], Router_href(new Route(9, [Config_getDefaultDiaryDate(model.Session)])), ["children", "Diary"]]))), createElement("a", createObj(ofArray([["className", "navbar-item"], Router_href(new Route(18, [])), ["children", "Physical"]]))), createElement("a", createObj(ofArray([["className", "navbar-item"], Router_href(new Route(29, [])), ["children", "Financial"]]))), createElement("a", createObj(ofArray([["className", "navbar-item"], Router_href(new Route(32, [])), ["children", "Fitness"]])))]) : singleton(createElement("a", createObj(ofArray([["className", "navbar-item"], Router_href(new Route(0, [])), ["children", "Home"]]))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("div", createObj(ofArray([["className", "navbar-end"], (arg = ofArray([createElement("a", createObj(ofArray([["className", "navbar-item"], Router_href(new Route(8, [])), ["children", "Contact"]]))), createElement("a", createObj(ofArray([["className", "navbar-item"], Router_href(new Route(2, [])), ["children", "About"]]))), createElement("div", createObj(ofArray([["className", "navbar-item"], (elems = (isLoggedIn ? ((name_12 = Session__GetCurrentName(model.Session), ofArray([button(ofArray([new Option(0, [getProfileColour(Session__GetCurrentProfileId(model.Session))]), new Option(18, [(_arg) => {
        Router_modifyLocation(new Route(4, []));
    }])]), ofArray([icon(empty_1(), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(8, []))), []))), createElement("span", {
        children: Interop_reactApi.Children.toArray([name_12]),
    })])), button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(18, [(_arg_1) => {
        dispatch(new Msg(0, []));
    }])]), ofArray([icon(empty_1(), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(10, []))), []))), createElement("span", {
        children: Interop_reactApi.Children.toArray(["Logout"]),
    })]))]))) : singleton(button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(18, [(_arg_2) => {
        Router_modifyLocation(new Route(3, []));
    }])]), ofArray([icon(empty_1(), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(9, []))), []))), createElement("span", {
        children: Interop_reactApi.Children.toArray(["Login"]),
    })])))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))])])))]), ["children", Interop_reactApi.Children.toArray(Array.from(arg))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_6))])])));
}

function displayTimer(model, dispatch) {
    if ((model.CurrentTimerExpiry != null) && (compare(value_67(model.CurrentTimerExpiry), model.CurrentTimestamp) > 0)) {
        const now = common.getNow();
        const duration = op_Subtraction(value_67(model.CurrentTimerExpiry), now);
        return list_1(ofArray([new List_Option(8, [ofArray([new Modifier_IModifier(0, [new Color_IColor(11, [])]), new Modifier_IModifier(29, [new Spacing_TypeAndDirection(7, []), new Spacing_Amount(2, [])])])]), new List_Option(1, [])]), ofArray([button(ofArray([new Option(0, [new Color_IColor(17, [])]), new Option(4, []), new Option(16, [model.InProgress])]), toList(delay(() => {
            const durationStr = format("{0:D2}:{1:D2}:{2:D2}", hours(duration), minutes(duration), seconds(duration));
            return singleton_1(createElement("span", {
                children: Interop_reactApi.Children.toArray([durationStr]),
            }));
        }))), button(ofArray([new Option(0, [new Color_IColor(7, [])]), new Option(4, []), new Option(16, [model.InProgress]), new Option(18, [(_arg) => {
            dispatch(new Msg(10, []));
        }])]), singleton(icon(empty_1(), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(19, []))), [])))))]));
    }
    else {
        return defaultOf();
    }
}

function handlePermissionCheckState(dispatch, permission, status) {
    if (status.onchange == null) {
        console.log(some(`Updating onChange event listener for permission: ${permission}`));
        status.onchange = ((_arg) => {
            console.warn(some(`Permission ${permission} state changed to ${status.state}`));
            dispatch(new Msg(11, [permission, status]));
        });
    }
    if (status.state === "denied") {
        return closeable(`Denied permission: ${permission}`, new Class(3, []));
    }
    else {
        return defaultOf();
    }
}

function displayPermissionsWarnings(dispatch, model) {
    return map((tuple) => tuple[1], toList_1(map_1((permission, status) => handlePermissionCheckState(dispatch, permission, status), model.CheckedPermissions)));
}

function displayVersionWarning(model) {
    if (model.Session.UserInfo != null) {
        const serverVersion = last(split(value_67(model.Session.UserInfo).ServerVersion, ["/"], void 0, 0));
        if ((Config_releaseVersion !== serverVersion) && (Config_releaseVersion !== "development")) {
            return closeable(`Client version '${Config_releaseVersion}' not the same as server version '${serverVersion}'. Please refresh`, new Class(4, []));
        }
        else {
            return defaultOf();
        }
    }
    else {
        return defaultOf();
    }
}

export function view(model, dispatch) {
    const activeWorkoutRecord = Session__GetActiveWorkoutRecord(model.Session);
    const children_8 = toList(delay(() => append(singleton_1(viewMainNavBar(model, dispatch)), delay(() => append(singleton_1(displayTimer(model, dispatch)), delay(() => append(singleton_1(displayVersionWarning(model)), delay(() => {
        let arg;
        return append(singleton_1((arg = displayPermissionsWarnings(dispatch, model), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(arg)),
        }))), delay(() => {
            if (activeWorkoutRecord != null) {
                const patternInput = value_67(activeWorkoutRecord);
                return singleton_1(list_1(ofArray([new List_Option(8, [ofArray([new Modifier_IModifier(0, [new Color_IColor(8, [])]), new Modifier_IModifier(29, [new Spacing_TypeAndDirection(7, []), new Spacing_Amount(2, [])])])]), new List_Option(1, [])]), ofArray([button(ofArray([new Option(0, [new Color_IColor(0, [])]), new Option(4, []), new Option(16, [model.InProgress]), new Option(18, [(_arg) => {
                    Router_modifyLocation(new Route(38, [patternInput[1].WorkoutId]));
                }])]), toList(delay(() => {
                    const duration = op_Subtraction(common.getNow(), patternInput[0].EventTimestamp);
                    const durationStr = format("{0:D2}:{1:D2}:{2:D2}", hours(duration), minutes(duration), seconds(duration));
                    return singleton_1(createElement("span", {
                        children: Interop_reactApi.Children.toArray([durationStr]),
                    }));
                }))), button(ofArray([new Option(0, [new Color_IColor(7, [])]), new Option(16, [model.InProgress]), new Option(18, [(_arg_1) => {
                    if (window.confirm(`${"Confirm abort"}:
${"Are you sure you want to abort the workout?"}`)) {
                        (() => {
                            dispatch(new Msg(6, []));
                        })();
                    }
                }])]), singleton(createElement("span", {
                    children: Interop_reactApi.Children.toArray(["Abort"]),
                }))), button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(16, [model.InProgress]), new Option(18, [(_arg_3) => {
                    if (window.confirm(`${"Confirm"}:
${"Are you sure you want to conclude the workout?"}`)) {
                        (() => {
                            dispatch(new Msg(5, []));
                        })();
                    }
                }])]), singleton(createElement("span", {
                    children: Interop_reactApi.Children.toArray(["Conclude"]),
                })))])));
            }
            else {
                return empty_2();
            }
        }));
    }))))))));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
    });
}

