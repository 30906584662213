import { Record } from "../fable-library.4.1.4/Types.js";
import { record_type, list_type, string_type } from "../fable-library.4.1.4/Reflection.js";
import { field } from "../Fable.Form.2.0.0/Base.fs.js";
import { isNullOrEmpty } from "../fable-library.4.1.4/String.js";

export class Attributes$1 extends Record {
    constructor(Label, Placeholder, HtmlAttributes) {
        super();
        this.Label = Label;
        this.Placeholder = Placeholder;
        this.HtmlAttributes = HtmlAttributes;
    }
}

export function Attributes$1_$reflection(gen0) {
    return record_type("Fable.Form.Simple.Field.TextField.Attributes`1", [gen0], Attributes$1, () => [["Label", string_type], ["Placeholder", string_type], ["HtmlAttributes", list_type(gen0)]]);
}

export function form() {
    return (build) => ((config) => field(isNullOrEmpty, build, config));
}

