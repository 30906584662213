import { FitnessClientModule_$ctor } from "../../FitnessClientModule.js";
import { Remoting_buildProxy_64DC51C } from "../../fable_modules/Fable.Remoting.Client.7.25.0/Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder } from "../../fable_modules/Fable.Remoting.Client.7.25.0/Remoting.fs.js";
import { isNullOrWhiteSpace, replace, format, printf, toText } from "../../fable_modules/fable-library.4.1.4/String.js";
import { getWorkoutId, WorkoutRecord__IsActive, getExerciseId, RepsWithWeightRecord__E1RMDisplay, FitnessService_$reflection, IntervalsRepsRecord_$reflection, IntervalsDurationRecord_$reflection, EnduranceRecord_$reflection, RepsWithBodyWeightRecord_$reflection, RecordService$1_$reflection, RepsWithWeightRecord_$reflection } from "../../Omnicv.Shared/FitnessAPI.js";
import { Record, toString, Union } from "../../fable_modules/fable-library.4.1.4/Types.js";
import { record_type, int32_type, string_type, class_type, name as name_4, lambda_type, unit_type, tuple_type, int64_type, union_type } from "../../fable_modules/fable-library.4.1.4/Reflection.js";
import { ofSeq, cons, sum, max, head as head_1, filter, map, append, find, empty, isEmpty, collect, singleton, ofArray, item, insertAt, removeAt, length, findIndex } from "../../fable_modules/fable-library.4.1.4/List.js";
import { compare as compare_1, equals } from "../../fable_modules/fable-library.4.1.4/BigInt.js";
import * as flex_form from "../../../src/javascript/flex-form.js";
import { Route, Router_modifyLocation, Route_$reflection } from "../../Omnicv.Client.Common/Router.js";
import { Option, button } from "../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Modifier_IModifier, TextAlignment_Option, Screen, Size_ISize, Color_IColor } from "../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { Option as Option_1, icon } from "../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_i } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Icons__ToIcon } from "../../Omnicv.Client.Common/SharedView.js";
import { Session__SiteSettings, Icons } from "../../Omnicv.Client.Common/SharedView.js";
import { Option as Option_2, columns } from "../../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { Option as Option_3, column } from "../../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { createElement } from "react";
import { Markdown_ToHtml_1BFEAEDB } from "../../fable_modules/Fable.Formatting.Markdown.1.0.1/Markdown.fs.js";
import { DOMAttr } from "../../fable_modules/Fable.React.9.3.0/Fable.React.Props.fs.js";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.6.0/Interop.fs.js";
import { timeFormatOptions, splitSeconds } from "../../Omnicv.Client.Common/Utils.js";
import { map as map_1, value as value_6 } from "../../fable_modules/fable-library.4.1.4/Option.js";
import { formatDistanceToNow } from "date-fns";
import { FSharpMap__get_Keys, tryFind, empty as empty_2, ofList, FSharpMap__ContainsKey, FSharpMap__TryFind, FSharpMap__get_Item } from "../../fable_modules/fable-library.4.1.4/Map.js";
import { distinct, List_groupBy, List_distinct } from "../../fable_modules/fable-library.4.1.4/Seq2.js";
import { curry2, compare, structuralHash, equals as equals_2, dateHash, stringHash, int64ToString, int32ToString, comparePrimitives, numberHash } from "../../fable_modules/fable-library.4.1.4/Util.js";
import { compare as compare_2, equals as equals_1, utcNow } from "../../fable_modules/fable-library.4.1.4/Date.js";
import { toDateTime, toString as toString_1, fromDateTime } from "../../fable_modules/fable-library.4.1.4/DateOnly.js";
import { sortDescending, empty as empty_1, singleton as singleton_1, append as append_1, delay, toList } from "../../fable_modules/fable-library.4.1.4/Seq.js";
import * as common from "../../../src/javascript/common.js";
import { StringBuilder__Append_Z721C83C5, StringBuilder__Append_Z524259A4, StringBuilder_$ctor_Z721C83C5 } from "../../fable_modules/fable-library.4.1.4/System.Text.js";
import { defaultOf } from "../../fable_modules/fable-library.4.1.4/Util.js";

export const fitnessModule = FitnessClientModule_$ctor();

export const repsWithWeightRecordService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, arg_1) => toText(printf("/api/auth/mod/fit/%s/%s"))("RepsWithWeightRecord")(arg_1), RemotingModule_createApi()), RecordService$1_$reflection(RepsWithWeightRecord_$reflection()));

export const repsWithBodyWeightRecordService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, arg_1) => toText(printf("/api/auth/mod/fit/%s/%s"))("RepsWithBodyWeightRecord")(arg_1), RemotingModule_createApi()), RecordService$1_$reflection(RepsWithBodyWeightRecord_$reflection()));

export const enduranceRecordService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, arg_1) => toText(printf("/api/auth/mod/fit/%s/%s"))("EnduranceRecord")(arg_1), RemotingModule_createApi()), RecordService$1_$reflection(EnduranceRecord_$reflection()));

export const intervalsDurationRecordService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, arg_1) => toText(printf("/api/auth/mod/fit/%s/%s"))("IntervalsDurationRecord")(arg_1), RemotingModule_createApi()), RecordService$1_$reflection(IntervalsDurationRecord_$reflection()));

export const intervalsRepsRecordService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, arg_1) => toText(printf("/api/auth/mod/fit/%s/%s"))("IntervalsRepsRecord")(arg_1), RemotingModule_createApi()), RecordService$1_$reflection(IntervalsRepsRecord_$reflection()));

export const fitnessService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, arg_1) => toText(printf("/api/auth/mod/fit-main/%s"))(arg_1), RemotingModule_createApi()), FitnessService_$reflection());

export class RemoveType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Delete", "Unlink"];
    }
}

export function RemoveType_$reflection() {
    return union_type("Omnicv.Client.Pages.Fitness.Common.RemoveType", [], RemoveType, () => [[], []]);
}

export class MoveAction extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Up", "Down", "Top", "Bottom"];
    }
}

export function MoveAction_$reflection() {
    return union_type("Omnicv.Client.Pages.Fitness.Common.MoveAction", [], MoveAction, () => [[], [], [], []]);
}

export function moveEntryInList(entries, entryId, moveAction) {
    const index = findIndex((entry) => equals(flex_form.getId(entry), entryId), entries) | 0;
    const isLast = index === (length(entries) - 1);
    const targetIndex = (moveAction.tag === 2) ? ((index === 0) ? void 0 : 0) : ((moveAction.tag === 1) ? (isLast ? void 0 : (index + 1)) : ((moveAction.tag === 3) ? (isLast ? void 0 : (length(entries) - 1)) : ((index === 0) ? void 0 : (index - 1))));
    if (targetIndex == null) {
        return entries;
    }
    else {
        const targetIndex_1 = targetIndex | 0;
        const source_1 = removeAt(index, entries);
        return insertAt(targetIndex_1, item(index, entries), source_1);
    }
}

export class FitnessAction extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Reorder", "Delete", "Unlink", "Edit", "View", "CloneData", "MarkFavourite"];
    }
}

export function FitnessAction_$reflection() {
    return union_type("Omnicv.Client.Pages.Fitness.Common.FitnessAction", [], FitnessAction, () => [[["Item", lambda_type(tuple_type(int64_type, MoveAction_$reflection()), unit_type)]], [["Item", lambda_type(int64_type, unit_type)]], [["Item", lambda_type(int64_type, unit_type)]], [["Item", lambda_type(int64_type, Route_$reflection())]], [["Item", lambda_type(int64_type, Route_$reflection())]], [["Item", lambda_type(int64_type, unit_type)]], [["Item", lambda_type(int64_type, unit_type)]]]);
}

function createFitnessListAction(id, action) {
    switch (action.tag) {
        case 1:
            return singleton(button(ofArray([new Option(0, [new Color_IColor(8, [])]), new Option(5, []), new Option(18, [(_arg_4) => {
                if (window.confirm(`${"Confirm delete"}:
${"Are you sure you want to delete this entry?"}`)) {
                    (() => {
                        action.fields[0](id);
                    })();
                }
            }])]), singleton(icon(singleton(new Option_1(0, [new Size_ISize(0, [])])), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(2, []))), []))))));
        case 2:
            return singleton(button(ofArray([new Option(0, [new Color_IColor(12, [])]), new Option(5, []), new Option(18, [(_arg_5) => {
                if (window.confirm(`${"Confirm unlink"}:
${"Are you sure you want to unlink this entry?"}`)) {
                    (() => {
                        action.fields[0](id);
                    })();
                }
            }])]), singleton(icon(singleton(new Option_1(0, [new Size_ISize(0, [])])), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(3, []))), []))))));
        case 5:
            return singleton(button(ofArray([new Option(0, [new Color_IColor(6, [])]), new Option(5, []), new Option(18, [(_arg_6) => {
                action.fields[0](id);
            }])]), singleton(icon(singleton(new Option_1(0, [new Size_ISize(0, [])])), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(1, []))), []))))));
        case 6:
            return singleton(button(ofArray([new Option(0, [new Color_IColor(12, [])]), new Option(5, []), new Option(18, [(_arg_7) => {
                action.fields[0](id);
            }])]), singleton(icon(singleton(new Option_1(0, [new Size_ISize(0, [])])), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(18, []))), []))))));
        case 3:
            return singleton(button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(5, []), new Option(18, [(_arg_8) => {
                Router_modifyLocation(action.fields[0](id));
            }])]), singleton(icon(singleton(new Option_1(0, [new Size_ISize(0, [])])), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(4, []))), []))))));
        case 4:
            return singleton(button(ofArray([new Option(0, [new Color_IColor(5, [])]), new Option(5, []), new Option(18, [(_arg_9) => {
                Router_modifyLocation(action.fields[0](id));
            }])]), singleton(icon(singleton(new Option_1(0, [new Size_ISize(0, [])])), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(13, []))), []))))));
        default: {
            const moveFunc = action.fields[0];
            return ofArray([button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(5, []), new Option(18, [(_arg) => {
                moveFunc([id, new MoveAction(0, [])]);
            }])]), singleton(icon(singleton(new Option_1(0, [new Size_ISize(0, [])])), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(14, []))), []))))), button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(5, []), new Option(18, [(_arg_1) => {
                moveFunc([id, new MoveAction(1, [])]);
            }])]), singleton(icon(singleton(new Option_1(0, [new Size_ISize(0, [])])), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(15, []))), []))))), button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(5, []), new Option(18, [(_arg_2) => {
                moveFunc([id, new MoveAction(2, [])]);
            }])]), singleton(icon(singleton(new Option_1(0, [new Size_ISize(0, [])])), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(16, []))), []))))), button(ofArray([new Option(0, [new Color_IColor(4, [])]), new Option(5, []), new Option(18, [(_arg_3) => {
                moveFunc([id, new MoveAction(3, [])]);
            }])]), singleton(icon(singleton(new Option_1(0, [new Size_ISize(0, [])])), singleton(Fa_i(singleton(Icons__ToIcon(new Icons(17, []))), [])))))]);
        }
    }
}

export function createFitnessListActions(actions, id) {
    return collect((action) => createFitnessListAction(id, action), actions);
}

const defaultDisplayWorkoutActions = singleton(new FitnessAction(4, [(arg) => (new Route(38, [arg]))]));

export function displayWorkout(actions, workout) {
    const finalActions = isEmpty(actions) ? defaultDisplayWorkoutActions : actions;
    return columns(ofArray([new Option_2(4, []), new Option_2(1, [])]), ofArray([column(empty(), singleton(workout.Name)), column(empty(), singleton(createElement("div", {
        dangerouslySetInnerHTML: {
            __html: Markdown_ToHtml_1BFEAEDB(workout.Description),
        },
    }))), column(empty(), createFitnessListActions(finalActions, workout.Id))]));
}

const defaultDisplayExerciseForRecordingActions = singleton(new FitnessAction(4, [(arg) => (new Route(42, [arg]))]));

export function displayClickableColumn(onClick, caption) {
    return column(singleton(new Option_3(3, [ofArray([new DOMAttr(40, [onClick]), ["style", {
        cursor: "pointer",
    }]])])), singleton(caption));
}

export function displayExerciseForRecording(actions, workoutId, exercise) {
    const finalActions = isEmpty(actions) ? defaultDisplayExerciseForRecordingActions : actions;
    const children = singleton(columns(ofArray([new Option_2(4, []), new Option_2(1, [])]), ofArray([displayClickableColumn((_arg) => {
        let copyOfStruct;
        Router_modifyLocation(new Route(45, [exercise.ContentTypeId, exercise.Id, (copyOfStruct = workoutId, toString(copyOfStruct))]));
    }, exercise.Name), column(singleton(new Option_3(4, [singleton(new Modifier_IModifier(5, [new Screen(3, []), new TextAlignment_Option(3, [])]))])), createFitnessListActions(finalActions, exercise.Id))])));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

function formatSeconds(durationSecs) {
    const patternInput = splitSeconds(durationSecs);
    return format("{0:D2}:{1:D2}", patternInput[0], patternInput[1]);
}

export function displayRepsWithWeight(record) {
    let copyOfStruct;
    return [`${record.Weight}kg × ${record.Repetitions}, E1RM ${RepsWithWeightRecord__E1RMDisplay(record)}kg`, record.Notes, replace(name_4((copyOfStruct = record, RepsWithWeightRecord_$reflection())), "Record", "")];
}

export function displayRepsWithBodyWeightRecord(record) {
    let copyOfStruct;
    return [`Body weight × ${record.Repetitions}`, record.Notes, replace(name_4((copyOfStruct = record, RepsWithBodyWeightRecord_$reflection())), "Record", "")];
}

export function displayEnduranceRecord(record) {
    let copyOfStruct;
    return [`${record.DistanceKm}km in ${formatSeconds(record.DurationSec)}`, record.Notes, replace(name_4((copyOfStruct = record, EnduranceRecord_$reflection())), "Record", "")];
}

export function displayIntervalsDurationRecord(record) {
    let copyOfStruct;
    return [`${record.NumExercises} exercises; work ${formatSeconds(record.WorkDurationSec)}; rest ${formatSeconds(record.RestDurationSec)}`, record.Notes, replace(name_4((copyOfStruct = record, IntervalsDurationRecord_$reflection())), "Record", "")];
}

export function displayIntervalsRepsRecord(record) {
    let copyOfStruct;
    return [`${record.NumExercises} × ${record.NumExercises}; rest ${formatSeconds(record.RestDurationSec)}`, record.Notes, replace(name_4((copyOfStruct = record, IntervalsRepsRecord_$reflection())), "Record", "")];
}

export function displayExerciseRecordCommon(session, deleteFunc, copyDataFunc, eventInfo, main, notes, recordName) {
    const descriptor = find((t) => (t.Name.indexOf(recordName) >= 0), Session__SiteSettings(session).FitnessContentTypes);
    const actions = append(map(value_6, filter((option_2) => (option_2 != null), ofArray([map_1((arg) => (new FitnessAction(1, [arg])), deleteFunc), map_1((arg_1) => (new FitnessAction(5, [arg_1])), copyDataFunc)]))), ofArray([new FitnessAction(4, [(id) => (new Route(44, [descriptor.Id, id]))]), new FitnessAction(3, [(id_1) => (new Route(46, [descriptor.Id, id_1]))])]));
    const hasNotes = (notes == null) ? "" : " [notes]";
    return columns(ofArray([new Option_2(4, []), new Option_2(1, [])]), ofArray([column(empty(), singleton(formatDistanceToNow(eventInfo.EventTimestamp, timeFormatOptions))), column(empty(), singleton(main + hasNotes)), column(empty(), createFitnessListActions(actions, eventInfo.Id))]));
}

function getExerciseRecordDisplayInfo(record) {
    switch (record.tag) {
        case 1:
            return displayRepsWithBodyWeightRecord(record.fields[0]);
        case 2:
            return displayEnduranceRecord(record.fields[0]);
        case 3:
            return displayIntervalsDurationRecord(record.fields[0]);
        case 4:
            return displayIntervalsRepsRecord(record.fields[0]);
        default:
            return displayRepsWithWeight(record.fields[0]);
    }
}

export function displayExerciseRecord(session, deleteFunc, exerciseRecord_, exerciseRecord__1) {
    const exerciseRecord = [exerciseRecord_, exerciseRecord__1];
    const patternInput = getExerciseRecordDisplayInfo(exerciseRecord[1]);
    return displayExerciseRecordCommon(session, deleteFunc, void 0, exerciseRecord[0], patternInput[0], patternInput[1], patternInput[2]);
}

export function displayExerciseRecordEx(session, exerciseMap, exerciseRecord_, exerciseRecord__1) {
    const exerciseRecord = [exerciseRecord_, exerciseRecord__1];
    const patternInput = getExerciseRecordDisplayInfo(exerciseRecord[1]);
    return displayExerciseRecordCommon(session, void 0, void 0, exerciseRecord[0], (FSharpMap__get_Item(exerciseMap, getExerciseId(exerciseRecord[0], exerciseRecord[1])).Name + ": ") + patternInput[0], patternInput[1], patternInput[2]);
}

function getRepetitionsFromExerciseRecord(exerciseRecord_, exerciseRecord__1) {
    const record = exerciseRecord__1;
    if (record.tag === 1) {
        return record.fields[0].Repetitions | 0;
    }
    else {
        return 0;
    }
}

function getE1RMFromExerciseRecord(exerciseRecord_, exerciseRecord__1) {
    const record = exerciseRecord__1;
    if (record.tag === 0) {
        return RepsWithWeightRecord__E1RMDisplay(record.fields[0]);
    }
    else {
        return 0;
    }
}

export function getSummaryFromExerciseRecords(exerciseRecords) {
    const emptyRes = ["", ""];
    if (length(List_distinct(map((tupledArg) => tupledArg[0].ContentTypeId, exerciseRecords), {
        Equals: (x, y) => (x === y),
        GetHashCode: numberHash,
    })) !== 1) {
        return emptyRes;
    }
    else {
        const head = head_1(exerciseRecords)[1];
        switch (head.tag) {
            case 0: {
                const best = max(map((tupledArg_1) => getE1RMFromExerciseRecord(tupledArg_1[0], tupledArg_1[1]), exerciseRecords), {
                    Compare: comparePrimitives,
                });
                return ["Best E1RM", best.toString() + "kg"];
            }
            case 1:
                return ["Total", int32ToString(sum(map((tupledArg_2) => getRepetitionsFromExerciseRecord(tupledArg_2[0], tupledArg_2[1]), exerciseRecords), {
                    GetZero: () => 0,
                    Add: (x_2, y_2) => (x_2 + y_2),
                }))];
            default:
                return emptyRes;
        }
    }
}

class WorkoutRecordDescriptor extends Record {
    constructor(Date$, Name, Time, Progress, Count) {
        super();
        this.Date = Date$;
        this.Name = Name;
        this.Time = Time;
        this.Progress = Progress;
        this.Count = (Count | 0);
    }
}

function WorkoutRecordDescriptor_$reflection() {
    return record_type("Omnicv.Client.Pages.Fitness.Common.WorkoutRecordDescriptor", [], WorkoutRecordDescriptor, () => [["Date", class_type("System.DateOnly")], ["Name", string_type], ["Time", string_type], ["Progress", string_type], ["Count", int32_type]]);
}

function WorkoutRecordDescriptor_FromWorkoutRecord(workoutsMap, workoutRecordData) {
    const record = workoutRecordData[1];
    const eventInfo = workoutRecordData[0];
    const isActive = WorkoutRecord__IsActive(record, eventInfo, utcNow());
    let progress;
    const copyOfStruct = record.Progress * 100;
    progress = format('{0:' + "F2" + '}', copyOfStruct);
    let name;
    const matchValue = FSharpMap__TryFind(workoutsMap, record.WorkoutId);
    name = ((matchValue == null) ? int64ToString(record.Id) : matchValue.Name);
    return new WorkoutRecordDescriptor(fromDateTime(eventInfo.EventTimestamp), name, formatDistanceToNow(eventInfo.EventTimestamp, timeFormatOptions), (`${progress}% done`) + (isActive ? " / active" : ""), 1);
}

function WorkoutRecordDescriptor_FromExerciseRecord(exercisesMap, exerciseRecordData) {
    const eventInfo = exerciseRecordData[0];
    let name;
    const matchValue = FSharpMap__TryFind(exercisesMap, getExerciseId(exerciseRecordData[0], exerciseRecordData[1]));
    name = ((matchValue == null) ? int64ToString(eventInfo.Id) : matchValue.Name);
    return new WorkoutRecordDescriptor(fromDateTime(eventInfo.EventTimestamp), name, formatDistanceToNow(eventInfo.EventTimestamp, timeFormatOptions), "100%", 1);
}

function WorkoutRecordDescriptor_DisplayGroupAsSingle_Z40DA17B4(descriptors) {
    let final;
    if (length(descriptors) === 1) {
        final = head_1(descriptors);
    }
    else {
        const inputRecord = head_1(descriptors);
        final = (new WorkoutRecordDescriptor(inputRecord.Date, inputRecord.Name, inputRecord.Time, inputRecord.Progress, length(descriptors)));
    }
    return columns(singleton(new Option_2(4, [])), toList(delay(() => append_1(singleton_1(column(empty(), singleton(final.Name))), delay(() => append_1(singleton_1(column(empty(), singleton(final.Time))), delay(() => append_1(singleton_1(column(empty(), singleton(final.Progress))), delay(() => ((final.Count > 1) ? singleton_1(column(empty(), singleton(`${final.Count} times`))) : empty_1()))))))))));
}

function WorkoutRecordDescriptor__get_GroupingKey(this$) {
    return this$.Name + this$.Progress;
}

function displayWorkoutRecord(workoutsMap, workoutRecordData_, workoutRecordData__1) {
    const workoutRecordData = [workoutRecordData_, workoutRecordData__1];
    const record = workoutRecordData[1];
    const eventInfo = workoutRecordData[0];
    return columns(singleton(new Option_2(4, [])), toList(delay(() => append_1(singleton_1(column(empty(), singleton(formatDistanceToNow(eventInfo.EventTimestamp, timeFormatOptions)))), delay(() => append_1(FSharpMap__ContainsKey(workoutsMap, record.WorkoutId) ? singleton_1(column(empty(), singleton(FSharpMap__get_Item(workoutsMap, record.WorkoutId).Name))) : singleton_1(column(empty(), singleton(int64ToString(eventInfo.Id)))), delay(() => append_1(singleton_1(column(empty(), toList(delay(() => {
        let copyOfStruct;
        return singleton_1(`${(copyOfStruct = (record.Progress * 100), format('{0:' + "F2" + '}', copyOfStruct))}% done`);
    })))), delay(() => singleton_1(column(empty(), createFitnessListActions(singleton(new FitnessAction(3, [(arg) => (new Route(49, [arg]))])), eventInfo.Id))))))))))));
}

function displayDescriptors(date, descriptors) {
    let arg;
    return append(singleton(createElement("div", {
        className: "divider",
        children: `${toString_1(date)} (${(arg = toDateTime(date, 0), common.getDayName(arg, ""))}): ${length(descriptors)} record(s)`,
    })), map((tupledArg) => WorkoutRecordDescriptor_DisplayGroupAsSingle_Z40DA17B4(tupledArg[1]), List_groupBy(WorkoutRecordDescriptor__get_GroupingKey, descriptors, {
        Equals: (x, y) => (x === y),
        GetHashCode: stringHash,
    })));
}

function displayWorkoutRecordGroup(workoutsMap, workoutRecordLookup, date, workoutRecordData) {
    return displayDescriptors(date, map((arg_1) => WorkoutRecordDescriptor_FromWorkoutRecord(workoutsMap, arg_1), workoutRecordData));
}

function displayWorkoutRecordGroupPlain(workoutsMap, _workoutRecordLookup, date, workoutRecordData) {
    let arg;
    return append(singleton(createElement("div", {
        className: "divider",
        children: `${toString_1(date)} (${(arg = toDateTime(date, 0), common.getDayName(arg, ""))}): ${length(workoutRecordData)} record(s)`,
    })), map((tupledArg) => displayWorkoutRecord(workoutsMap, tupledArg[0], tupledArg[1]), workoutRecordData));
}

function groupWorkoutRecords(workoutRecordData) {
    return List_groupBy((tupledArg) => fromDateTime(tupledArg[0].EventTimestamp), workoutRecordData, {
        Equals: equals_1,
        GetHashCode: dateHash,
    });
}

export function displayWorkoutRecords(data) {
    const workoutRecordLookup = ofList(List_groupBy((tupledArg) => getWorkoutId(tupledArg[0], tupledArg[1]), data.ExerciseRecords, {
        Equals: equals_2,
        GetHashCode: structuralHash,
    }), {
        Compare: compare,
    });
    return collect((tupledArg_1) => displayWorkoutRecordGroup(empty_2({
        Compare: compare_1,
    }), workoutRecordLookup, tupledArg_1[0], tupledArg_1[1]), groupWorkoutRecords(data.WorkoutRecords));
}

export function displayWorkoutRecordsOnly(workoutsMap, workoutRecordData) {
    return collect((tupledArg) => displayWorkoutRecordGroup(workoutsMap, empty_2({
        Compare: compare,
    }), tupledArg[0], tupledArg[1]), groupWorkoutRecords(workoutRecordData));
}

function displayDayWorkoutLog(session, date, workoutsMap, exercisesMap, workouts, exercises) {
    let arg;
    const sb = StringBuilder_$ctor_Z721C83C5(`${toString_1(date)} (${(arg = toDateTime(date, 0), common.getDayName(arg, ""))}): `);
    let workoutElements;
    if (workouts != null) {
        StringBuilder__Append_Z524259A4(StringBuilder__Append_Z721C83C5(sb, "w: "), length(value_6(workouts)));
        workoutElements = map((tupledArg) => displayWorkoutRecord(workoutsMap, tupledArg[0], tupledArg[1]), value_6(workouts));
    }
    else {
        workoutElements = empty();
    }
    let exercisesElements;
    if (exercises != null) {
        StringBuilder__Append_Z524259A4(StringBuilder__Append_Z721C83C5(sb, " | e: "), length(value_6(exercises)));
        exercisesElements = map((tupledArg_1) => displayExerciseRecordEx(session, exercisesMap, tupledArg_1[0], tupledArg_1[1]), value_6(exercises));
    }
    else {
        exercisesElements = empty();
    }
    return cons(createElement("div", {
        className: "divider",
        children: toString(sb),
    }), append(workoutElements, exercisesElements));
}

export function displayWorkoutLog(session, workoutLog) {
    let source_1;
    const workoutsMap = ofList(map((w) => [w.Id, w], workoutLog.Workouts), {
        Compare: compare_1,
    });
    const exercisesMap = ofList(map((w_1) => [w_1.Id, w_1], workoutLog.Exercises), {
        Compare: compare_1,
    });
    const workoutByDate = ofList(List_groupBy((tupledArg) => fromDateTime(tupledArg[0].EventTimestamp), workoutLog.RecentWorkoutRecords, {
        Equals: equals_1,
        GetHashCode: dateHash,
    }), {
        Compare: compare_2,
    });
    const exercisesByDate = ofList(List_groupBy((tupledArg_1) => fromDateTime(tupledArg_1[0].EventTimestamp), workoutLog.RecentExerciseRecords, {
        Equals: equals_1,
        GetHashCode: dateHash,
    }), {
        Compare: compare_2,
    });
    return collect((date) => displayDayWorkoutLog(session, date, workoutsMap, exercisesMap, tryFind(date, workoutByDate), tryFind(date, exercisesByDate)), ofSeq(sortDescending(distinct((source_1 = FSharpMap__get_Keys(workoutByDate), append_1(FSharpMap__get_Keys(exercisesByDate), source_1)), {
        Equals: equals_1,
        GetHashCode: dateHash,
    }), {
        Compare: compare_2,
    })));
}

export function displayWorkoutRecordsOnlyPlain(workoutsMap, workoutRecordData) {
    return collect((tupledArg) => displayWorkoutRecordGroupPlain(workoutsMap, empty_2({
        Compare: compare,
    }), tupledArg[0], tupledArg[1]), groupWorkoutRecords(workoutRecordData));
}

export function displayDailySingleExerciseRecords(session, displayRecord, group_, group__1) {
    let arg;
    const group = [group_, group__1];
    const exerciseRecords = group[1];
    const date = group[0];
    return append(singleton(createElement("div", {
        className: "divider",
        children: `${toString_1(date)} (${(arg = toDateTime(date, 0), common.getDayName(arg, ""))}): ${length(exerciseRecords)} record(s)`,
    })), map(curry2(displayRecord)(session), exerciseRecords));
}

function displayDailyExerciseRecords(session, deleteFunc, group_, group__1) {
    let arg, patternInput, caption;
    const group = [group_, group__1];
    const exerciseRecords = group[1];
    const date = group[0];
    return append(singleton(createElement("div", {
        className: "divider",
        children: `${toString_1(date)} (${(arg = toDateTime(date, 0), common.getDayName(arg, ""))}): ${length(exerciseRecords)} record(s)`,
    })), append(map((tupledArg) => displayExerciseRecord(session, deleteFunc, tupledArg[0], tupledArg[1]), exerciseRecords), singleton((patternInput = getSummaryFromExerciseRecords(exerciseRecords), (caption = patternInput[0], !isNullOrWhiteSpace(caption) ? columns(ofArray([new Option_2(4, []), new Option_2(1, [])]), ofArray([column(empty(), singleton(createElement("b", {
        children: Interop_reactApi.Children.toArray([caption]),
    }))), column(empty(), singleton(patternInput[1]))])) : defaultOf())))));
}

export function displayExerciseRecords(session, deleteFunc, exerciseRecords) {
    return collect((tupledArg_1) => displayDailyExerciseRecords(session, deleteFunc, tupledArg_1[0], tupledArg_1[1]), List_groupBy((tupledArg) => fromDateTime(tupledArg[0].EventTimestamp), exerciseRecords, {
        Equals: equals_1,
        GetHashCode: dateHash,
    }));
}

