import { FinancialClientModule_$ctor } from "../../FinancialClientModule.js";
import { Remoting_buildProxy_64DC51C } from "../../fable_modules/Fable.Remoting.Client.7.25.0/Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withRouteBuilder } from "../../fable_modules/Fable.Remoting.Client.7.25.0/Remoting.fs.js";
import { printf, toText } from "../../fable_modules/fable-library.4.1.4/String.js";
import { FinancialService_$reflection, AssetValueService_$reflection } from "../../Omnicv.Shared/FinancialAPI.js";
import { reverse, singleton as singleton_1, ofArray, map } from "../../fable_modules/fable-library.4.1.4/List.js";
import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library.4.1.4/Seq.js";
import { formatDistanceToNow } from "date-fns";
import { timeFormatOptions } from "../../Omnicv.Client.Common/Utils.js";
import * as react from "react";
import * as common from "../../../src/javascript/common.js";
import { Option, button } from "../../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Size_ISize, Color_IColor } from "../../fable_modules/Fulma.3.0.0/Common.fs.js";
import { value as value_1 } from "../../fable_modules/fable-library.4.1.4/Option.js";
import { Option as Option_1, icon } from "../../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_i } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Icons__ToIcon } from "../../Omnicv.Client.Common/SharedView.js";
import { Icons } from "../../Omnicv.Client.Common/SharedView.js";
import { TableOption, table } from "../../fable_modules/Fulma.3.0.0/Elements/Table.fs.js";

export const financialModule = FinancialClientModule_$ctor();

export const assetValueService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, arg_1) => toText(printf("/api/auth/mod/fin-val/%s"))(arg_1), RemotingModule_createApi()), AssetValueService_$reflection());

export const financialService = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder((arg, arg_1) => toText(printf("/api/auth/mod/fin-hi/%s"))(arg_1), RemotingModule_createApi()), FinancialService_$reflection());

export function valuesToTable(targetCurrency, data, deleteEntry) {
    let children_14, children_12;
    const rows = map((value) => {
        const children_6 = toList(delay(() => {
            let children;
            return append(singleton((children = [formatDistanceToNow(value.Timestamp, timeFormatOptions)], react.createElement("td", {}, ...children))), delay(() => {
                let children_2;
                return append(singleton((children_2 = [common.formatCurrencyValue(targetCurrency, value.Value)], react.createElement("td", {}, ...children_2))), delay(() => {
                    let children_4;
                    return (deleteEntry != null) ? singleton((children_4 = [button(ofArray([new Option(0, [new Color_IColor(8, [])]), new Option(5, []), new Option(18, [(_arg) => {
                        if (window.confirm(`${"Confirm delete"}:
${"Are you sure you want to delete this entry?"}`)) {
                            (() => {
                                value_1(deleteEntry)(value.Id);
                            })();
                        }
                    }])]), singleton_1(icon(singleton_1(new Option_1(0, [new Size_ISize(0, [])])), singleton_1(Fa_i(singleton_1(Icons__ToIcon(new Icons(2, []))), [])))))], react.createElement("td", {}, ...children_4))) : empty();
                }));
            }));
        }));
        return react.createElement("tr", {}, ...children_6);
    }, reverse(data));
    return table(ofArray([new TableOption(2, []), new TableOption(1, [])]), ofArray([(children_14 = [(children_12 = [react.createElement("th", {}, "Date"), react.createElement("th", {}, "Value")], react.createElement("tr", {}, ...children_12))], react.createElement("thead", {}, ...children_14)), react.createElement("tbody", {}, ...rows)]));
}

