import { useReact_useEffect_311B4086, useFeliz_React__React_useState_Static_1505, useReact_useElementRef, useReact_useEffect_7331F961 } from "../Feliz.2.6.0/React.fs.js";
import { defaultArg, value as value_39 } from "../fable-library.4.1.4/Option.js";
import { createElement } from "react";
import React from "react";
import { cons, choose, mapIndexed, filter as filter_1, map, isEmpty, ofArray, singleton, append, length, exists, empty } from "../fable-library.4.1.4/List.js";
import { replace, getCharAtIndex, join, isNullOrWhiteSpace, compare } from "../fable-library.4.1.4/String.js";
import { createObj } from "../fable-library.4.1.4/Util.js";
import { Helpers_combineClasses } from "../Feliz.Bulma.3.0.0/ElementBuilders.fs.js";
import { startImmediate } from "../fable-library.4.1.4/Async.js";
import { singleton as singleton_1 } from "../fable-library.4.1.4/AsyncBuilder.js";
import { empty as empty_1, singleton as singleton_2, append as append_1, delay, toList } from "../fable-library.4.1.4/Seq.js";
import { Interop_reactApi } from "../Feliz.2.6.0/Interop.fs.js";

export function Hook_useOnClickOutside(ref, handler) {
    useReact_useEffect_7331F961(() => {
        const listener = (event) => {
            let copyOfStruct;
            if ((ref.current == null) ? true : ((copyOfStruct = value_39(ref.current), copyOfStruct.contains(event.target)))) {
            }
            else {
                handler(event);
            }
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return {
            Dispose() {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            },
        };
    }, [ref, handler]);
}

export function TagsInputComponent_input(tagsInputComponent_inputInputProps) {
    const p = tagsInputComponent_inputInputProps.p;
    const wrapperRef = useReact_useElementRef();
    const patternInput = (p.value != null) ? [value_39(p.value), (value) => {
    }] : useFeliz_React__React_useState_Static_1505(defaultArg(p.defaultValue, empty()));
    const tags = patternInput[0];
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(false);
    const setDropdownShown = patternInput_1[1];
    const isDropdownShown = patternInput_1[0];
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const setIsActive = patternInput_2[1];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505("");
    const setInputValue = patternInput_3[1];
    const inputValue = patternInput_3[0];
    const patternInput_4 = useFeliz_React__React_useState_Static_1505(empty());
    const setAutoComplete = patternInput_4[1];
    const autoComplete = patternInput_4[0];
    const patternInput_5 = useFeliz_React__React_useState_Static_1505(false);
    const setInputLoading = patternInput_5[1];
    const inputLoading = patternInput_5[0];
    Hook_useOnClickOutside(wrapperRef, (_arg) => {
        setDropdownShown(false);
        setIsActive(false);
    });
    const changeTags = (value_2) => {
        patternInput[1](value_2);
        if (p.onTagsChanged != null) {
            value_39(p.onTagsChanged)(value_2);
        }
    };
    const addTag = (isFromDropdown, t) => {
        const t_1 = p.autoTrim ? t.trim() : t;
        const isDuplicate = exists((x_2) => (compare(x_2, t_1, !p.caseSensitive) === 0), tags);
        const isInAutoComplete = exists((x_3) => (compare(x_3, t_1, !p.caseSensitive) === 0), autoComplete);
        if (((!isNullOrWhiteSpace(t_1) && ((isDuplicate && p.allowDuplicates) ? true : !isDuplicate)) && ((isInAutoComplete && p.allowOnlyAutoCompleteValues) ? true : !p.allowOnlyAutoCompleteValues)) && ((length(tags) + 1) <= p.maxTags)) {
            changeTags(append(tags, singleton(t_1)));
        }
        if (p.closeDropdownOnItemSelect && isFromDropdown) {
            setDropdownShown(false);
        }
    };
    const autoCompleteItems = inputLoading ? singleton(createElement("div", createObj(Helpers_combineClasses("dropdown-item", ofArray([["className", "empty-title"], ["children", p.loadingLabel]]))))) : (isEmpty(autoComplete) ? singleton(createElement("div", createObj(Helpers_combineClasses("dropdown-item", ofArray([["className", "empty-title"], ["children", p.noResultsLabel]]))))) : map((x_4) => createElement("a", createObj(Helpers_combineClasses("dropdown-item", ofArray([["children", x_4], ["onClick", (_arg_2) => {
        addTag(true, x_4);
    }]])))), autoComplete));
    useReact_useEffect_311B4086(() => {
        startImmediate(singleton_1.Delay(() => {
            const matchValue = p.autoCompleteSource;
            let matchResult;
            if (isDropdownShown) {
                if (matchValue != null) {
                    matchResult = 0;
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0: {
                    const f = matchValue;
                    setInputLoading(true);
                    return singleton_1.Bind(f(inputValue), (_arg_1) => {
                        let vals;
                        setInputLoading(false);
                        setAutoComplete((vals = _arg_1, p.removeSelectedFromAutoComplete ? filter_1((arg) => !exists((x_1) => (compare(x_1, arg, !p.caseSensitive) === 0), tags), vals) : vals));
                        return singleton_1.Zero();
                    });
                }
                default: {
                    setAutoComplete(empty());
                    return singleton_1.Zero();
                }
            }
        }));
    }, [inputValue, isDropdownShown, tags]);
    return createElement("div", createObj(Helpers_combineClasses("control", toList(delay(() => append_1(inputLoading ? singleton_2(["className", "is-loading"]) : empty_1(), delay(() => {
        let elems_4, elems_3;
        return singleton_2((elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", toList(delay(() => append_1(singleton_2("tags-input"), delay(() => (patternInput_2[0] ? singleton_2("is-active") : empty_1()))))))], ["ref", wrapperRef], (elems_3 = toList(delay(() => append_1(mapIndexed((i_1, v_3) => createElement("span", createObj(Helpers_combineClasses("tag", toList(delay(() => append_1(p.tagProperties, delay(() => {
            let elems;
            return singleton_2((elems = toList(delay(() => append_1(singleton_2(v_3), delay(() => (p.tagsRemovable ? singleton_2(createElement("button", createObj(Helpers_combineClasses("delete", singleton(["onClick", (_arg_3) => {
                changeTags(choose((x) => x, mapIndexed((i, v_1) => {
                    if (i !== i_1) {
                        return v_1;
                    }
                    else {
                        return void 0;
                    }
                }, tags)));
            }]))))) : empty_1()))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems))]));
        }))))))), tags), delay(() => append_1(singleton_2(createElement("input", createObj(cons(["type", "text"], Helpers_combineClasses("input", ofArray([["autoFocus", isDropdownShown], ["onFocus", (_arg_4) => {
            setDropdownShown(true);
            setIsActive(true);
        }], ["onKeyDown", (k) => {
            if (k.key.toUpperCase() === "ENTER") {
                addTag(false, inputValue);
                setInputValue("");
            }
            else if (getCharAtIndex(k.key, 0) === p.delimiter) {
                addTag(false, replace(inputValue, p.delimiter, "\u0000"));
                setInputValue("");
                k.preventDefault();
            }
        }], ["onChange", (ev) => {
            setInputValue(ev.target.value);
        }], ["placeholder", p.placeholder]])))))), delay(() => {
            let elems_2;
            return ((p.autoCompleteSource != null) && isDropdownShown) ? singleton_2(createElement("div", createObj(Helpers_combineClasses("dropdown-menu", singleton((elems_2 = [createElement("div", {
                className: "dropdown-content",
                children: Interop_reactApi.Children.toArray(Array.from(autoCompleteItems)),
            })], ["children", Interop_reactApi.Children.toArray(Array.from(elems_2))])))))) : empty_1();
        })))))), ["children", Interop_reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", Interop_reactApi.Children.toArray(Array.from(elems_4))]));
    })))))));
}

