import { value, some } from "../fable_modules/fable-library.4.1.4/Option.js";

export const isDevelopment = false;

/**
 * Logs error to the console during development
 */
export function Log_developmentError(error) {
    if (isDevelopment) {
        console.error(some(error));
    }
}

export const Config_placeLookupId = "placeLookupInput";

const Config_demoUsers = process.env["DEMO_USER"] ? process.env["DEMO_USER"] : '';

export const Config_releaseVersion = process.env["RELEASE_VERSION"] ? process.env["RELEASE_VERSION"] : '';

export function Config_isDemoUser(session) {
    return Config_demoUsers === value(session.UserInfo).Name;
}

const Config_defaultDate = "now";

export function Config_getDefaultDiaryDate(session) {
    if (Config_isDemoUser(session)) {
        const fromDate = process.env["DEMO_USER_RANGE_FROM"] ? process.env["DEMO_USER_RANGE_FROM"] : '';
        if (fromDate.length > 0) {
            return fromDate;
        }
        else {
            const toDate = process.env["DEMO_USER_RANGE_TO"] ? process.env["DEMO_USER_RANGE_TO"] : '';
            if (toDate.length > 0) {
                return toDate;
            }
            else {
                return Config_defaultDate;
            }
        }
    }
    else {
        return Config_defaultDate;
    }
}

export function Config_getUserTimeZone(session) {
    if (Config_isDemoUser(session)) {
        return "UTC";
    }
    else {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
}

