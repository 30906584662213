import { Record } from "../fable-library.4.1.4/Types.js";
import { record_type, list_type, tuple_type, string_type } from "../fable-library.4.1.4/Reflection.js";
import { field } from "../Fable.Form.2.0.0/Base.fs.js";
import { isNullOrEmpty } from "../fable-library.4.1.4/String.js";

export class Attributes extends Record {
    constructor(Label, Options) {
        super();
        this.Label = Label;
        this.Options = Options;
    }
}

export function Attributes_$reflection() {
    return record_type("Fable.Form.Simple.Field.RadioField.Attributes", [], Attributes, () => [["Label", string_type], ["Options", list_type(tuple_type(string_type, string_type))]]);
}

export function form() {
    return (build) => ((config) => field(isNullOrEmpty, build, config));
}

