import { toString, Union, Record } from "../fable_modules/fable-library.4.1.4/Types.js";
import { Response_validateFullName, Response_validateDateOfBirth, Response_ProfileInfo_Default, Response_ProfileInfo_$reflection, Response_UserInfo_$reflection } from "../Omnicv.Shared/API.js";
import { union_type, unit_type, int32_type, class_type, record_type, bool_type, string_type, option_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";
import { Cmd_none } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { commonAuthService } from "../Omnicv.Client.Common/CommonServer.js";
import { ofArray, empty, singleton } from "../fable_modules/fable-library.4.1.4/List.js";
import { stringToEnum, exnToDisplayString } from "../Omnicv.Client.Common/Utils.js";
import { empty as empty_1, map, singleton as singleton_1, append, delay, toList } from "../fable_modules/fable-library.4.1.4/Seq.js";
import { Class, closeable } from "../Omnicv.Client.Common/Components/Notification.js";
import { defaultOf } from "../fable_modules/fable-library.4.1.4/Util.js";
import { Icons, Session__GetCurrentProfileId } from "../Omnicv.Client.Common/SharedView.js";
import { Option, h2, h1 } from "../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { Footer_div, footer, content, Header_icon, Header_Title_Option, Header_title, header, card } from "../fable_modules/Fulma.3.0.0/Components/Card.fs.js";
import { Fa_IconOption, Fa_i } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { content as content_1 } from "../fable_modules/Fulma.3.0.0/Elements/Content.fs.js";
import { columns } from "../fable_modules/Fulma.3.0.0/Layouts/Columns.fs.js";
import { column } from "../fable_modules/Fulma.3.0.0/Layouts/Column.fs.js";
import { int32ToString } from "../fable_modules/fable-library.4.1.4/Util.js";
import { toString as toString_1, fromDateTime } from "../fable_modules/fable-library.4.1.4/DateOnly.js";
import { Option as Option_1, button, list } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Size_ISize, Color_IColor } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { Option as Option_2, icon } from "../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import { Fa_i as Fa_i_1 } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { Icons__ToIcon } from "../Omnicv.Client.Common/SharedView.js";
import { Router_newUrl, Route, Router_modifyLocation } from "../Omnicv.Client.Common/Router.js";
import { createElement } from "react";
import { Interop_reactApi } from "../fable_modules/Feliz.2.6.0/Interop.fs.js";
import { value as value_2 } from "../fable_modules/fable-library.4.1.4/Option.js";
import { FlexFormBase$1__Render_2B595, FlexForm$1__get_Fields, FlexFormFields$1__AddDate_2636DDCF, FlexFormFields$1__AddSelection_158231B6, FlexFormFields$1__AddCheckbox_264AA22A, FlexFormFields$1__AddText_Z44011C05, FlexForm$1_$ctor_2ABDDB55 } from "../Omnicv.Client.Common/Components/FlexForm.js";

export class ViewProfile_Model extends Record {
    constructor(UserInfo, Error$, InProgress) {
        super();
        this.UserInfo = UserInfo;
        this.Error = Error$;
        this.InProgress = InProgress;
    }
}

export function ViewProfile_Model_$reflection() {
    return record_type("Omnicv.Client.Pages.MyProfile.Component.ViewProfile.Model", [], ViewProfile_Model, () => [["UserInfo", option_type(Response_UserInfo_$reflection())], ["Error", option_type(string_type)], ["InProgress", bool_type]]);
}

export class ViewProfile_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ReloadClicked", "Succeeded", "Failed", "Delete", "DeleteSucceeded", "SetCurrentProfile"];
    }
}

export function ViewProfile_Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.MyProfile.Component.ViewProfile.Msg", [], ViewProfile_Msg, () => [[], [["Item", Response_UserInfo_$reflection()]], [["Item", class_type("System.Exception")]], [["Item", int32_type]], [["Item", unit_type]], [["Item", int32_type]]]);
}

export function ViewProfile_init(session) {
    return [new ViewProfile_Model(session.UserInfo, void 0, false), Cmd_none()];
}

export function ViewProfile_update(msg, model) {
    switch (msg.tag) {
        case 1:
            return [new ViewProfile_Model(msg.fields[0], model.Error, false), Cmd_none()];
        case 3:
            return [new ViewProfile_Model(model.UserInfo, model.Error, true), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, commonAuthService.DeleteProfile, msg.fields[0], () => (new ViewProfile_Msg(4, [void 0])), (arg_4) => (new ViewProfile_Msg(2, [arg_4])))];
        case 4:
            return [new ViewProfile_Model(model.UserInfo, model.Error, true), singleton((dispatch) => {
                dispatch(new ViewProfile_Msg(0, []));
            })];
        case 5:
            return [model, Cmd_none()];
        case 2:
            return [new ViewProfile_Model(void 0, exnToDisplayString(msg.fields[0]), false), Cmd_none()];
        default:
            return [new ViewProfile_Model(model.UserInfo, model.Error, true), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, commonAuthService.GetUserInfo, void 0, (arg) => (new ViewProfile_Msg(1, [arg])), (arg_1) => (new ViewProfile_Msg(2, [arg_1])))];
    }
}

export function ViewProfile_view(session, model, dispatch) {
    const children_8 = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't fetch profile ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => {
            let matchValue_1, userInfo, currentProfileId;
            return append((matchValue_1 = model.UserInfo, (matchValue_1 != null) ? ((userInfo = matchValue_1, (currentProfileId = (Session__GetCurrentProfileId(session) | 0), append(singleton_1(h1(empty())(singleton("Your profile"))), delay(() => append(singleton_1(h2(singleton(new Option(6, [])))(singleton(userInfo.Name))), delay(() => append(singleton_1(card(empty(), ofArray([header(empty(), singleton(Header_title(singleton(new Header_Title_Option(0, [])), singleton("Main user info")))), header(empty(), ofArray([Header_icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-user-alt"])), []))), Header_title(empty(), singleton(userInfo.Name))])), content(empty(), singleton(content_1(empty(), ofArray([columns(empty(), ofArray([column(empty(), singleton("Id:")), column(empty(), singleton(int32ToString(userInfo.Id)))])), columns(empty(), ofArray([column(empty(), singleton("Email:")), column(empty(), singleton(userInfo.Email))]))]))))]))), delay(() => map((profile) => {
                let copyOfStruct;
                return card(empty(), ofArray([header(empty(), ofArray([Header_icon(empty(), singleton(Fa_i(singleton(new Fa_IconOption(11, ["fas fa-user-friends"])), []))), Header_title(singleton(new Header_Title_Option(0, [])), singleton(`Profile ${profile.FullName}`))])), content(empty(), singleton(content_1(empty(), ofArray([columns(empty(), ofArray([column(empty(), singleton("Id:")), column(empty(), singleton(int32ToString(profile.Id)))])), columns(empty(), ofArray([column(empty(), singleton("Primary:")), column(empty(), singleton(toString(profile.IsPrimary)))])), columns(empty(), ofArray([column(empty(), singleton("Date of birth:")), column(empty(), singleton((copyOfStruct = fromDateTime(profile.DateOfBirth), toString_1(copyOfStruct))))]))])))), footer(empty(), singleton(Footer_div(empty(), singleton(list(empty(), toList(delay(() => append((currentProfileId !== profile.Id) ? singleton_1(button(ofArray([new Option_1(0, [new Color_IColor(5, [])]), new Option_1(16, [model.InProgress]), new Option_1(5, []), new Option_1(18, [(_arg) => {
                    dispatch(new ViewProfile_Msg(5, [profile.Id]));
                }])]), singleton(icon(singleton(new Option_2(0, [new Size_ISize(0, [])])), singleton(Fa_i_1(singleton(Icons__ToIcon(new Icons(11, []))), [])))))) : empty_1(), delay(() => append(singleton_1(button(ofArray([new Option_1(0, [new Color_IColor(4, [])]), new Option_1(16, [model.InProgress]), new Option_1(5, []), new Option_1(18, [(_arg_1) => {
                    Router_modifyLocation(new Route(6, [profile.Id]));
                }])]), singleton(icon(singleton(new Option_2(0, [new Size_ISize(0, [])])), singleton(Fa_i_1(singleton(Icons__ToIcon(new Icons(4, []))), [])))))), delay(() => singleton_1(button(ofArray([new Option_1(0, [new Color_IColor(8, [])]), new Option_1(16, [model.InProgress]), new Option_1(5, []), new Option_1(18, [(_arg_2) => {
                    if (window.confirm(`${"Confirm deletion"}:
${"Are you sure you want to delete this profile?"}`)) {
                        (() => {
                            dispatch(new ViewProfile_Msg(3, [profile.Id]));
                        })();
                    }
                }])]), singleton(icon(singleton(new Option_2(0, [new Size_ISize(0, [])])), singleton(Fa_i_1(singleton(Icons__ToIcon(new Icons(2, []))), [])))))))))))))))))]));
            }, userInfo.Profiles)))))))))) : singleton_1(createElement("i", {
                children: ["Not loaded"],
            }))), delay(() => singleton_1(list(empty(), ofArray([button(ofArray([new Option_1(0, [new Color_IColor(6, [])]), new Option_1(16, [model.InProgress]), new Option_1(18, [(_arg_3) => {
                dispatch(new ViewProfile_Msg(0, []));
            }])]), toList(delay(() => (model.InProgress ? singleton_1(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Loading..."]),
            })) : singleton_1(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Reload"]),
            })))))), button(ofArray([new Option_1(0, [new Color_IColor(9, [])]), new Option_1(16, [model.InProgress]), new Option_1(18, [(_arg_4) => {
                Router_modifyLocation(new Route(5, []));
            }])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["New profile"]),
            }))), button(ofArray([new Option_1(0, [new Color_IColor(9, [])]), new Option_1(16, [model.InProgress])]), singleton(createElement("span", {
                children: Interop_reactApi.Children.toArray(["Edit user information"]),
            })))])))));
        }));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_8)),
    });
}

export class AddEditProfile_Model extends Record {
    constructor(ProfileInfo, Error$, InProgress) {
        super();
        this.ProfileInfo = ProfileInfo;
        this.Error = Error$;
        this.InProgress = InProgress;
    }
}

export function AddEditProfile_Model_$reflection() {
    return record_type("Omnicv.Client.Pages.MyProfile.Component.AddEditProfile.Model", [], AddEditProfile_Model, () => [["ProfileInfo", option_type(Response_ProfileInfo_$reflection())], ["Error", option_type(string_type)], ["InProgress", bool_type]]);
}

export class AddEditProfile_Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Request", "RequestSucceeded", "RecordUpdated", "AddSucceeded", "EditSucceeded", "Submit", "Failure"];
    }
}

export function AddEditProfile_Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.MyProfile.Component.AddEditProfile.Msg", [], AddEditProfile_Msg, () => [[["Item", int32_type]], [["Item", Response_ProfileInfo_$reflection()]], [["Item", Response_ProfileInfo_$reflection()]], [["Item", int32_type]], [["Item", unit_type]], [], [["Item", class_type("System.Exception")]]]);
}

export function AddEditProfile_initNew() {
    return [new AddEditProfile_Model(Response_ProfileInfo_Default(), void 0, false), Cmd_none()];
}

export function AddEditProfile_initEdit(id) {
    return [new AddEditProfile_Model(void 0, void 0, false), singleton((dispatch) => {
        dispatch(new AddEditProfile_Msg(0, [id]));
    })];
}

export function AddEditProfile_update(msg, model) {
    switch (msg.tag) {
        case 0:
            return [new AddEditProfile_Model(model.ProfileInfo, model.Error, true), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, commonAuthService.GetProfile, msg.fields[0], (arg) => (new AddEditProfile_Msg(1, [arg])), (arg_1) => (new AddEditProfile_Msg(6, [arg_1])))];
        case 1:
            return [new AddEditProfile_Model(msg.fields[0], model.Error, false), Cmd_none()];
        case 5: {
            const profile = value_2(model.ProfileInfo);
            return [new AddEditProfile_Model(model.ProfileInfo, model.Error, true), (profile.Id > 0) ? Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, commonAuthService.EditProfile, profile, () => (new AddEditProfile_Msg(4, [void 0])), (arg_4) => (new AddEditProfile_Msg(6, [arg_4]))) : Cmd_OfAsyncWith_either((x_2) => {
                Cmd_OfAsync_start(x_2);
            }, commonAuthService.AddProfile, profile, (arg_6) => (new AddEditProfile_Msg(3, [arg_6])), (arg_7) => (new AddEditProfile_Msg(6, [arg_7])))];
        }
        case 3:
            return [new AddEditProfile_Model(model.ProfileInfo, model.Error, false), Router_newUrl(new Route(4, []))];
        case 4:
            return [new AddEditProfile_Model(model.ProfileInfo, model.Error, false), Router_newUrl(new Route(4, []))];
        case 6:
            return [new AddEditProfile_Model(model.ProfileInfo, exnToDisplayString(msg.fields[0]), false), Cmd_none()];
        default:
            return [new AddEditProfile_Model(msg.fields[0], model.Error, model.InProgress), Cmd_none()];
    }
}

function AddEditProfile_noValidation(_arg) {
    return void 0;
}

const AddEditProfile_genders = ofArray([[0, "Male"], [1, "Female"], [2, "Other"]]);

const AddEditProfile_stringToGender = (value) => stringToEnum(AddEditProfile_genders, value);

export function AddEditProfile_view(model, dispatch) {
    const children = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Can't manage profile ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => {
            if (model.ProfileInfo != null) {
                const profile = value_2(model.ProfileInfo);
                const form = FlexForm$1_$ctor_2ABDDB55((arg_1) => {
                    dispatch(new AddEditProfile_Msg(2, [arg_1]));
                }, (_arg_1) => {
                    dispatch(new AddEditProfile_Msg(5, []));
                }, () => {
                    Router_modifyLocation(new Route(4, []));
                });
                FlexFormFields$1__AddText_Z44011C05(FlexFormFields$1__AddCheckbox_264AA22A(FlexFormFields$1__AddSelection_158231B6(FlexFormFields$1__AddDate_2636DDCF(FlexFormFields$1__AddText_Z44011C05(FlexForm$1__get_Fields(form), "FullName", Response_validateFullName, "Full name"), "DateOfBirth", Response_validateDateOfBirth, "DOB"), "Gender", AddEditProfile_genders, AddEditProfile_stringToGender, AddEditProfile_noValidation), "IsPrimary", AddEditProfile_noValidation, "Primary?"), "Country_IsoCode", AddEditProfile_noValidation, "Country");
                return singleton_1(FlexFormBase$1__Render_2B595(form, profile));
            }
            else {
                return singleton_1("Loading...");
            }
        }));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children)),
    });
}

