import { Record, Union } from "../../../fable_modules/fable-library.4.1.4/Types.js";
import { class_type, record_type, bool_type, list_type, tuple_type, int32_type, union_type, string_type } from "../../../fable_modules/fable-library.4.1.4/Reflection.js";
import { EventInfo_$reflection } from "../../../Omnicv.Shared.Common/CoreEventInfo.js";
import { UnifiedRecord_$reflection } from "../../Omnicv.Diary.Shared/DiaryAPI.js";
import { View_ViewConfig$2, View_Validation, View_Action$1, succeed, textField, append as append_1, View_Model$1, View_State, View_idle, View_Model$1_$reflection } from "../../../fable_modules/Fable.Form.Simple.4.0.0/Form.fs.js";
import { Session__GetCurrentProfileId } from "../../../Omnicv.Client.Common/SharedView.js";
import { singleton as singleton_1, append, length, empty } from "../../../fable_modules/fable-library.4.1.4/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { curry2 } from "../../../fable_modules/fable-library.4.1.4/Util.js";
import { unifiedService } from "../Server.js";
import { exnToDisplayString } from "../../../Omnicv.Client.Common/Utils.js";
import { Modifiers_ensureValidTrimmed } from "../../../Omnicv.Shared.Common/Validation.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library.4.1.4/Choice.js";
import { Attributes$1 } from "../../../fable_modules/Fable.Form.Simple.4.0.0/TextField.fs.js";
import { FieldConfig$4 } from "../../../fable_modules/Fable.Form.2.0.0/Base.fs.js";
import { asHtml } from "../../../fable_modules/Fable.Form.Simple.Bulma.4.0.0/Form.fs.js";
import { singleton, append as append_2, delay, toList } from "../../../fable_modules/fable-library.4.1.4/Seq.js";
import { h1 } from "../../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { defaultOf } from "../../../fable_modules/fable-library.4.1.4/Util.js";
import { displayUnifiedEntries } from "../DiaryClientUtils.js";
import { createElement } from "react";
import { Interop_reactApi } from "../../../fable_modules/Feliz.2.6.0/Interop.fs.js";

export class SearchType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ByTags", "General"];
    }
}

export function SearchType_$reflection() {
    return union_type("Omnicv.Client.Pages.Diary.SearchDiaryView.Component.SearchType", [], SearchType, () => [[["Item", string_type]], []]);
}

export class SearchData extends Record {
    constructor(SearchType, ProfileId, SearchQuery, SearchResults, IsLoading) {
        super();
        this.SearchType = SearchType;
        this.ProfileId = (ProfileId | 0);
        this.SearchQuery = SearchQuery;
        this.SearchResults = SearchResults;
        this.IsLoading = IsLoading;
    }
}

export function SearchData_$reflection() {
    return record_type("Omnicv.Client.Pages.Diary.SearchDiaryView.Component.SearchData", [], SearchData, () => [["SearchType", SearchType_$reflection()], ["ProfileId", int32_type], ["SearchQuery", string_type], ["SearchResults", list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))], ["IsLoading", bool_type]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["FormChanged", "Submit", "LoadMore", "Success", "SuccessMore", "Failure"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.Diary.SearchDiaryView.Component.Msg", [], Msg, () => [[["Item", View_Model$1_$reflection(SearchData_$reflection())]], [["Item", string_type]], [], [["Item", list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))]], [["Item", list_type(tuple_type(EventInfo_$reflection(), UnifiedRecord_$reflection()))]], [["Item", class_type("System.Exception")]]]);
}

export function init(session, searchType) {
    let temp;
    const profileId = Session__GetCurrentProfileId(session) | 0;
    if (searchType.tag === 1) {
        return [View_idle(new SearchData(searchType, profileId, "", empty(), false)), Cmd_none()];
    }
    else {
        return [(temp = View_idle(new SearchData(searchType, profileId, "", empty(), true)), new View_Model$1(temp.Values, new View_State(3, ["Search done on load"]), temp.ErrorTracking)), Cmd_OfAsyncWith_either((x) => {
            Cmd_OfAsync_start(x);
        }, curry2(unifiedService.SearchByTags)(profileId), searchType.fields[0], (arg) => (new Msg(3, [arg])), (arg_1) => (new Msg(5, [arg_1])))];
    }
}

export function update(msg, model) {
    let inputRecord_1, inputRecord_2, inputRecord_3;
    const processSuccess = (finalRecords) => {
        let inputRecord;
        return [new View_Model$1((inputRecord = model.Values, new SearchData(inputRecord.SearchType, inputRecord.ProfileId, inputRecord.SearchQuery, finalRecords, false)), new View_State(3, [`Search finished successfully, ${length(finalRecords)} records loaded`]), model.ErrorTracking), Cmd_none()];
    };
    switch (msg.tag) {
        case 1: {
            const searchQuery = msg.fields[0];
            return [new View_Model$1((inputRecord_1 = model.Values, new SearchData(inputRecord_1.SearchType, inputRecord_1.ProfileId, searchQuery, inputRecord_1.SearchResults, true)), new View_State(1, []), model.ErrorTracking), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, curry2(unifiedService.SearchGeneral)(model.Values.ProfileId), searchQuery, (arg) => (new Msg(3, [arg])), (arg_1) => (new Msg(5, [arg_1])))];
        }
        case 2:
            return [new View_Model$1((inputRecord_2 = model.Values, new SearchData(inputRecord_2.SearchType, inputRecord_2.ProfileId, inputRecord_2.SearchQuery, inputRecord_2.SearchResults, true)), new View_State(1, []), model.ErrorTracking), Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, curry2(unifiedService.SearchGeneral)(model.Values.ProfileId), model.Values.SearchQuery, (arg_3) => (new Msg(4, [arg_3])), (arg_4) => (new Msg(5, [arg_4])))];
        case 3:
            return processSuccess(msg.fields[0]);
        case 4:
            return processSuccess(append(model.Values.SearchResults, msg.fields[0]));
        case 5:
            return [new View_Model$1((inputRecord_3 = model.Values, new SearchData(inputRecord_3.SearchType, inputRecord_3.ProfileId, inputRecord_3.SearchQuery, empty(), false)), new View_State(2, [exnToDisplayString(msg.fields[0])]), model.ErrorTracking), Cmd_none()];
        default:
            return [msg.fields[0], Cmd_none()];
    }
}

const form = append_1(textField(new FieldConfig$4((value) => (new FSharpResult$2(0, [Modifiers_ensureValidTrimmed(value)])), (values) => values.SearchQuery, (newValue, values_1) => (new SearchData(values_1.SearchType, values_1.ProfileId, newValue, values_1.SearchResults, values_1.IsLoading)), (values_2) => ((values_2.SearchQuery.trim().length < 3) ? "Search query too short" : void 0), new Attributes$1("Search query", "Search something here", empty()))), succeed((arg) => (new Msg(1, [arg]))));

function viewForm(model, dispatch) {
    return asHtml(new View_ViewConfig$2(dispatch, (arg) => (new Msg(0, [arg])), new View_Action$1(0, ["Search diary"]), new View_Validation(1, [])))(form)(model);
}

export function view(model, dispatch) {
    const elms = toList(delay(() => ((model.State.tag === 3) ? append_2(singleton(h1(empty())(singleton_1("Search results"))), delay(() => append_2((model.Values.SearchType.tag === 1) ? singleton(viewForm(model, dispatch)) : singleton(defaultOf()), delay(() => {
        let children;
        return singleton((children = displayUnifiedEntries(model.Values.SearchResults, model.Values.IsLoading, (value) => {
        }), createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        })));
    })))) : append_2(singleton(h1(empty())(singleton_1("Search the diary"))), delay(() => singleton(viewForm(model, dispatch)))))));
    return createElement("div", {
        className: "container",
        children: Interop_reactApi.Children.toArray(Array.from(elms)),
    });
}

