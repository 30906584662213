import { Union, Record } from "../../fable_modules/fable-library.4.1.4/Types.js";
import { unit_type, array_type, fullName, union_type, class_type, string_type, list_type, tuple_type, option_type, int64_type, record_type, bool_type, int32_type } from "../../fable_modules/fable-library.4.1.4/Reflection.js";
import { printf, toFail, isNullOrWhiteSpace, split } from "../../fable_modules/fable-library.4.1.4/String.js";
import { parse } from "../../fable_modules/fable-library.4.1.4/Int32.js";
import { parse as parse_1 } from "../../fable_modules/fable-library.4.1.4/Boolean.js";
import { WorkoutRecordType, WorkoutCircuitData_$reflection, ExerciseRecord, RepsWithWeightRecord, IntervalsRepsRecord, IntervalsDurationRecord, EnduranceRecord, RepsWithBodyWeightRecord, IntervalsRepsRecord_$reflection, IntervalsDurationRecord_$reflection, EnduranceRecord_$reflection, RepsWithBodyWeightRecord_$reflection, RepsWithWeightRecord_$reflection, IntervalsRepsRecord_Default_Z524259C1, IntervalsDurationRecord_Default_Z524259C1, EnduranceRecord_Default_Z524259C1, RepsWithBodyWeightRecord_Default_Z524259C1, RepsWithWeightRecord_Default_Z524259C1, validateNotes, validateWeight, validateRepetitions, ExerciseRecordAddingInfo$1_$reflection, Exercise_$reflection } from "../../Omnicv.Shared/FitnessAPI.js";
import { tryUpdateLocationFromSuggestions, update as update_1, prepareEventInfoForSubmit, Model__UpdateEventInfo_Z45EFA9A8, initCommonNoRegistration, initCommon, Msg_$reflection as Msg_$reflection_1, Model_$reflection as Model_$reflection_1 } from "../../Omnicv.Client.Common/Components/EventInfoManager.js";
import { EventInfo__ClearLocation, PagingOptions, EventInfo, EventInfo_Default_7F9DDECF, EventInfo_$reflection } from "../../Omnicv.Shared.Common/CoreEventInfo.js";
import { equals as equals_1, fromInt32, toInt64, compare } from "../../fable_modules/fable-library.4.1.4/BigInt.js";
import { defaultArg, map, value as value_2 } from "../../fable_modules/fable-library.4.1.4/Option.js";
import * as flex_form from "../../../src/javascript/flex-form.js";
import { zip, toArray, mapIndexed, append as append_1, map as map_1, find, collect, cons, tryFind, singleton, filter, reverse, length, partition, head, isEmpty, ofArray, empty } from "../../fable_modules/fable-library.4.1.4/List.js";
import { Cmd_none, Cmd_map, Cmd_batch } from "../../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import * as common from "../../../src/javascript/common.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { uncurry2, dateHash, equals, curry2, curry3 } from "../../fable_modules/fable-library.4.1.4/Util.js";
import { Session__SiteSettings, Session__GetActiveWorkoutRecord, Session__GetCurrentProfileId } from "../../Omnicv.Client.Common/SharedView.js";
import { FlexFormWithFormlets$1_$ctor_Z7DF2018A, Formlet$1, FlexFormFields$1__CanSubmit_2B595, FlexFormTuple$2__get_FieldsFst, FlexFormFields$1__Render_2B595, FlexFormFields$1__AddDurationSecs_ZAAC24, FlexFormFields$1__AddPositiveIntOpt_414042FF, FlexFormFields$1__AddTextAreaOpt_Z44636648, FlexFormTuple$2__get_FieldsSnd, FlexFormFields$1__AddPositiveFloat_1D6F52FB, FlexFormFields$1__AddPositiveInt_ZAAC24, getSuggestions, addEventInfoWithSuggestionsToFlexForm, FlexFormTuple$2_$ctor_5AE4FFF0, FlexFormBase$1__Render_2B595, FlexForm$1__get_Fields, FlexFormFields$1__AddGroup_6DFDD678, FlexFormFields$1__AddDurationSecsWithSeconds_ZAAC24, noValidation, FlexFormFields$1__AddCheckbox_264AA22A, FlexForm$1_$ctor_2ABDDB55, FlexFormState, FlexFormBase$1__set_State_Z3615493C, setWorkoutRecordId, clearId } from "../../Omnicv.Client.Common/Components/FlexForm.js";
import { equals as equals_2, op_Addition, compare as compare_1, day, month, year, create } from "../../fable_modules/fable-library.4.1.4/Date.js";
import { displayWorkoutRecords, displayIntervalsRepsRecord, intervalsRepsRecordService, displayIntervalsDurationRecord, intervalsDurationRecordService, displayEnduranceRecord, enduranceRecordService, displayRepsWithBodyWeightRecord, repsWithBodyWeightRecordService, displayRepsWithWeight, repsWithWeightRecordService, displayExerciseRecordCommon, displayDailySingleExerciseRecords, fitnessService } from "./FitnessCommon.js";
import { fromSeconds } from "../../fable_modules/fable-library.4.1.4/TimeSpan.js";
import { Router_modifyLocation, Route, Router_newUrl } from "../../Omnicv.Client.Common/Router.js";
import { exnToDisplayString } from "../../Omnicv.Client.Common/Utils.js";
import { ofArray as ofArray_1, last, empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library.4.1.4/Seq.js";
import { Class, closeable } from "../../Omnicv.Client.Common/Components/Notification.js";
import { defaultOf } from "../../fable_modules/fable-library.4.1.4/Util.js";
import { Option, div } from "../../fable_modules/Fulma.3.0.0/Elements/Form/Control.fs.js";
import { container } from "../../fable_modules/Fulma.3.0.0/Layouts/Container.fs.js";
import { h3, h4, Option as Option_1, h2, h1 } from "../../fable_modules/Fulma.3.0.0/Elements/Heading.fs.js";
import { createElement } from "react";
import { Interop_reactApi } from "../../fable_modules/Feliz.2.6.0/Interop.fs.js";
import { List_groupBy } from "../../fable_modules/fable-library.4.1.4/Seq2.js";
import { fromDateTime } from "../../fable_modules/fable-library.4.1.4/DateOnly.js";
import { parse as parse_2 } from "../../fable_modules/fable-library.4.1.4/Long.js";
import { updateAt } from "../../fable_modules/fable-library.4.1.4/Array.js";

export class TimerOptions extends Record {
    constructor(DurationSecs, Start) {
        super();
        this.DurationSecs = (DurationSecs | 0);
        this.Start = Start;
    }
}

export function TimerOptions_$reflection() {
    return record_type("Omnicv.Client.Pages.Fitness.AddEditExerciseRecord.TimerOptions", [], TimerOptions, () => [["DurationSecs", int32_type], ["Start", bool_type]]);
}

export function TimerOptions_Parse_Z721C83C5(value) {
    const parts = split(value, [";"], void 0, 0);
    return new TimerOptions(parse(parts[0], 511, false, 32), parse_1(parts[1]));
}

export function TimerOptions__Serialise(this$) {
    return `${this$.DurationSecs};${this$.Start}`;
}

const defaultTimer = new TimerOptions(2 * 60, true);

export class RecordModel$1 extends Record {
    constructor(RecordId, WorkoutId, ExerciseId, Exercise, EIMModel, Record, PreviousEntries, InProgress, Error$, TimerOptions) {
        super();
        this.RecordId = RecordId;
        this.WorkoutId = WorkoutId;
        this.ExerciseId = ExerciseId;
        this.Exercise = Exercise;
        this.EIMModel = EIMModel;
        this.Record = Record;
        this.PreviousEntries = PreviousEntries;
        this.InProgress = InProgress;
        this.Error = Error$;
        this.TimerOptions = TimerOptions;
    }
}

export function RecordModel$1_$reflection(gen0) {
    return record_type("Omnicv.Client.Pages.Fitness.AddEditExerciseRecord.RecordModel`1", [gen0], RecordModel$1, () => [["RecordId", int64_type], ["WorkoutId", option_type(int64_type)], ["ExerciseId", int64_type], ["Exercise", option_type(Exercise_$reflection())], ["EIMModel", Model_$reflection_1()], ["Record", gen0], ["PreviousEntries", list_type(tuple_type(EventInfo_$reflection(), gen0))], ["InProgress", bool_type], ["Error", option_type(string_type)], ["TimerOptions", option_type(TimerOptions_$reflection())]]);
}

export function RecordModel$1__IsEditing(this$) {
    return compare(this$.RecordId, toInt64(fromInt32(0))) > 0;
}

export function RecordModel$1__GetExerciseId(this$) {
    if (compare(this$.ExerciseId, toInt64(fromInt32(0))) > 0) {
        return this$.ExerciseId;
    }
    else if (this$.Exercise != null) {
        return value_2(this$.Exercise).Id;
    }
    else {
        return flex_form.getExerciseId(this$.Record);
    }
}

export class RecordMsg$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RecordAUpdated", "RecordBUpdated", "FetchExercise", "FetchExerciseRecordAddingInfoSuccess", "FetchExerciseSuccess", "FetchSuccess", "FetchPreviousSuccess", "Failure", "Submit", "SubmitSuccess", "UpdateTimerOptions", "SetTimer", "EIMMsg"];
    }
}

export function RecordMsg$1_$reflection(gen0) {
    return union_type("Omnicv.Client.Pages.Fitness.AddEditExerciseRecord.RecordMsg`1", [gen0], RecordMsg$1, () => [[["Item", EventInfo_$reflection()]], [["Item", gen0]], [], [["Item", ExerciseRecordAddingInfo$1_$reflection(gen0)]], [["Item", Exercise_$reflection()]], [["Item", tuple_type(EventInfo_$reflection(), gen0)]], [["Item", list_type(tuple_type(EventInfo_$reflection(), gen0))]], [["Item", class_type("System.Exception")]], [], [["Item", EventInfo_$reflection()]], [["Item", TimerOptions_$reflection()]], [["Item", class_type("System.DateTime")]], [["Item", Msg_$reflection_1()]]]);
}

class AddEditExerciseRecordHandler$1 {
    constructor(createDefault, service, buildForm, displayRecord) {
        this.createDefault = createDefault;
        this.service = service;
        this.buildForm = buildForm;
        this.displayRecord = displayRecord;
    }
}

function AddEditExerciseRecordHandler$1_$reflection(gen0) {
    return class_type("Omnicv.Client.Pages.Fitness.AddEditExerciseRecord.AddEditExerciseRecordHandler`1", [gen0], AddEditExerciseRecordHandler$1);
}

function AddEditExerciseRecordHandler$1_$ctor_23B8DC89(createDefault, service, buildForm, displayRecord) {
    return new AddEditExerciseRecordHandler$1(createDefault, service, buildForm, displayRecord);
}

function AddEditExerciseRecordHandler$1__InitCommonWithInfo(this$, withEventInfo, eventInfo, workoutId, exerciseId, cmd) {
    const defaultValue = this$.createDefault(exerciseId);
    const patternInput = withEventInfo ? initCommon(eventInfo) : initCommonNoRegistration(eventInfo);
    return [new RecordModel$1(eventInfo.Id, workoutId, exerciseId, void 0, patternInput[0], defaultValue, empty(), true, void 0, void 0), Cmd_batch(ofArray([Cmd_map((arg) => (new RecordMsg$1(12, [arg])), patternInput[1]), cmd]))];
}

function AddEditExerciseRecordHandler$1__InitCommon(this$, withEventInfo, contentTypeId, recordId, workoutId, exerciseId, cmd) {
    let inputRecord;
    return AddEditExerciseRecordHandler$1__InitCommonWithInfo(this$, withEventInfo, (inputRecord = EventInfo_Default_7F9DDECF(common.getNow()), new EventInfo(recordId, contentTypeId, inputRecord.ProfileId, inputRecord.EventTimestamp, inputRecord.CreatedAt, inputRecord.UpdatedAt, inputRecord.PlaceId, inputRecord.PlaceName, inputRecord.Latitude, inputRecord.Longitude, inputRecord.LocationTz, inputRecord.LocationTzOffsetMin, inputRecord.ClientTz, inputRecord.ClientTzOffsetMin)), workoutId, exerciseId, cmd);
}

function AddEditExerciseRecordHandler$1__InitNew(this$, withEventInfo, session, contentTypeId, workoutId, exerciseId, noFetch) {
    let pagingOptions;
    const patternInput = AddEditExerciseRecordHandler$1__InitCommon(this$, withEventInfo, contentTypeId, 0n, workoutId, exerciseId, noFetch ? Cmd_none() : ((pagingOptions = (new PagingOptions(void 0, 10, false, void 0, void 0)), Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, curry3(this$.service.GetExerciseRecordAddingInfo)(Session__GetCurrentProfileId(session))(exerciseId), pagingOptions, (arg) => (new RecordMsg$1(3, [arg])), (arg_1) => (new RecordMsg$1(7, [arg_1]))))));
    const model = patternInput[0];
    return [new RecordModel$1(model.RecordId, model.WorkoutId, model.ExerciseId, model.Exercise, model.EIMModel, model.Record, model.PreviousEntries, model.InProgress, model.Error, AddEditExerciseRecordHandler$1__GetTimerOptions_Z524259C1(this$, exerciseId)), patternInput[1]];
}

function AddEditExerciseRecordHandler$1__InitEdit(this$, withEventInfo, session, contentTypeId, id) {
    return AddEditExerciseRecordHandler$1__InitCommon(this$, withEventInfo, contentTypeId, id, void 0, 0n, Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, curry2(this$.service.GetRecord)(Session__GetCurrentProfileId(session)), id, (arg) => (new RecordMsg$1(5, [arg])), (arg_1) => (new RecordMsg$1(7, [arg_1]))));
}

function AddEditExerciseRecordHandler$1__Update(this$, session, msg, model) {
    let inputRecord, eventInfo, inputRecord_1;
    const withEventInfo = (newModel, eventInfo_1) => (new RecordModel$1(newModel.RecordId, newModel.WorkoutId, newModel.ExerciseId, newModel.Exercise, Model__UpdateEventInfo_Z45EFA9A8(model.EIMModel, eventInfo_1), newModel.Record, newModel.PreviousEntries, newModel.InProgress, newModel.Error, newModel.TimerOptions));
    const handlePreviousEntriesUpdate = (result_1, triggerFetchExercise) => {
        let now, midnight, patternInput, todayRecords, previousRecords, lastDate, midnight_1;
        const newModel_1 = new RecordModel$1(model.RecordId, model.WorkoutId, model.ExerciseId, model.Exercise, model.EIMModel, model.Record, result_1, false, model.Error, model.TimerOptions);
        const activeWorkoutEventInfo = map((tuple_3) => tuple_3[0], Session__GetActiveWorkoutRecord(session));
        if ((isEmpty(result_1) && (activeWorkoutEventInfo == null)) ? true : RecordModel$1__IsEditing(model)) {
            return [newModel_1, Cmd_none()];
        }
        else {
            const sourceEventInfo = (activeWorkoutEventInfo != null) ? value_2(activeWorkoutEventInfo) : EventInfo__ClearLocation(head(result_1)[0]);
            const newRecord = isEmpty(result_1) ? newModel_1.Record : clearId(setWorkoutRecordId((now = common.getNow(), (midnight = create(year(now), month(now), day(now), 0, 0, 0, 0, 2), (patternInput = partition((tupledArg) => (compare_1(tupledArg[0].EventTimestamp, midnight) > 0), result_1), (todayRecords = patternInput[0], (previousRecords = patternInput[1], (length(todayRecords) > 0) ? head(todayRecords)[1] : ((lastDate = head(previousRecords)[0].EventTimestamp, (midnight_1 = create(year(lastDate), month(lastDate), day(lastDate), 0, 0, 0, 0, 2), head(reverse(filter((tupledArg_1) => (compare_1(tupledArg_1[0].EventTimestamp, midnight_1) > 0), previousRecords)))[1])))))))), void 0));
            const now_1 = common.getNow();
            return [withEventInfo(new RecordModel$1(newModel_1.RecordId, newModel_1.WorkoutId, newModel_1.ExerciseId, newModel_1.Exercise, newModel_1.EIMModel, newRecord, newModel_1.PreviousEntries, newModel_1.InProgress, newModel_1.Error, newModel_1.TimerOptions), new EventInfo(0n, sourceEventInfo.ContentTypeId, sourceEventInfo.ProfileId, now_1, now_1, now_1, sourceEventInfo.PlaceId, sourceEventInfo.PlaceName, sourceEventInfo.Latitude, sourceEventInfo.Longitude, sourceEventInfo.LocationTz, sourceEventInfo.LocationTzOffsetMin, sourceEventInfo.ClientTz, sourceEventInfo.ClientTzOffsetMin)), (!triggerFetchExercise ? true : RecordModel$1__IsEditing(model)) ? Cmd_none() : singleton((dispatch) => {
                dispatch(new RecordMsg$1(2, []));
            })];
        }
    };
    switch (msg.tag) {
        case 0:
            return [withEventInfo(model, msg.fields[0]), Cmd_none()];
        case 1:
            return [new RecordModel$1(model.RecordId, model.WorkoutId, model.ExerciseId, model.Exercise, model.EIMModel, msg.fields[0], model.PreviousEntries, model.InProgress, model.Error, model.TimerOptions), Cmd_none()];
        case 2:
            if (compare(model.ExerciseId, toInt64(fromInt32(0))) > 0) {
                return [new RecordModel$1(model.RecordId, model.WorkoutId, model.ExerciseId, model.Exercise, model.EIMModel, model.Record, model.PreviousEntries, true, model.Error, model.TimerOptions), Cmd_OfAsyncWith_either((x) => {
                    Cmd_OfAsync_start(x);
                }, curry2(fitnessService.GetExercise)(Session__GetCurrentProfileId(session)), model.ExerciseId, (arg_3) => (new RecordMsg$1(4, [arg_3])), (arg_4) => (new RecordMsg$1(7, [arg_4])))];
            }
            else {
                return [model, Cmd_none()];
            }
        case 4:
            return [new RecordModel$1(model.RecordId, model.WorkoutId, model.ExerciseId, msg.fields[0], model.EIMModel, model.Record, model.PreviousEntries, false, model.Error, model.TimerOptions), Cmd_none()];
        case 5: {
            const eventInfo_6 = msg.fields[0][0];
            return [(inputRecord = withEventInfo(model, eventInfo_6), new RecordModel$1(eventInfo_6.Id, inputRecord.WorkoutId, inputRecord.ExerciseId, inputRecord.Exercise, inputRecord.EIMModel, msg.fields[0][1], inputRecord.PreviousEntries, false, inputRecord.Error, inputRecord.TimerOptions)), singleton((dispatch_1) => {
                dispatch_1(new RecordMsg$1(2, []));
            })];
        }
        case 6:
            return handlePreviousEntriesUpdate(msg.fields[0], true);
        case 3: {
            const result_4 = msg.fields[0];
            const patternInput_3 = handlePreviousEntriesUpdate(result_4.ExerciseRecords, false);
            const newModel_2 = patternInput_3[0];
            return [new RecordModel$1(newModel_2.RecordId, newModel_2.WorkoutId, newModel_2.ExerciseId, result_4.Exercise, newModel_2.EIMModel, newModel_2.Record, newModel_2.PreviousEntries, newModel_2.InProgress, newModel_2.Error, newModel_2.TimerOptions), patternInput_3[1]];
        }
        case 8: {
            let timerCmd;
            const matchValue = model.TimerOptions;
            if (matchValue != null) {
                const timerOptions = matchValue;
                if (timerOptions.Start) {
                    const timerEnd = op_Addition(common.getNow(), fromSeconds(timerOptions.DurationSecs));
                    timerCmd = singleton((dispatch_2) => {
                        dispatch_2(new RecordMsg$1(11, [timerEnd]));
                    });
                }
                else {
                    timerCmd = Cmd_none();
                }
            }
            else {
                timerCmd = Cmd_none();
            }
            return [new RecordModel$1(model.RecordId, model.WorkoutId, model.ExerciseId, model.Exercise, model.EIMModel, model.Record, model.PreviousEntries, true, model.Error, model.TimerOptions), Cmd_batch(ofArray([Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, (eventInfo = prepareEventInfoForSubmit(session, model.EIMModel), (record) => {
                const newEventInfo = new EventInfo(eventInfo.Id, eventInfo.ContentTypeId, Session__GetCurrentProfileId(session), eventInfo.EventTimestamp, eventInfo.CreatedAt, eventInfo.UpdatedAt, eventInfo.PlaceId, eventInfo.PlaceName, eventInfo.Latitude, eventInfo.Longitude, eventInfo.LocationTz, eventInfo.LocationTzOffsetMin, eventInfo.ClientTz, eventInfo.ClientTzOffsetMin);
                const finalRecord = setWorkoutRecordId(record, map((record_1) => record_1.Id, map((tuple) => tuple[1], Session__GetActiveWorkoutRecord(session))));
                return (compare(eventInfo.Id, toInt64(fromInt32(0))) > 0) ? this$.service.Edit(newEventInfo, finalRecord) : this$.service.Add(newEventInfo, finalRecord);
            }), model.Record, (arg_6) => (new RecordMsg$1(9, [arg_6])), (arg_7) => (new RecordMsg$1(7, [arg_7]))), timerCmd]))];
        }
        case 11:
            return [model, Cmd_none()];
        case 9: {
            const eventInfo_8 = msg.fields[0];
            return [(inputRecord_1 = withEventInfo(model, eventInfo_8), new RecordModel$1(eventInfo_8.Id, inputRecord_1.WorkoutId, inputRecord_1.ExerciseId, inputRecord_1.Exercise, inputRecord_1.EIMModel, inputRecord_1.Record, inputRecord_1.PreviousEntries, false, inputRecord_1.Error, inputRecord_1.TimerOptions)), Router_newUrl(new Route(42, [RecordModel$1__GetExerciseId(model)]))];
        }
        case 10: {
            const timerOptions_1 = msg.fields[0];
            if ((model.TimerOptions == null) ? true : !equals(value_2(model.TimerOptions), timerOptions_1)) {
                AddEditExerciseRecordHandler$1__SetTimerOptions(this$, model.ExerciseId, timerOptions_1);
            }
            return [new RecordModel$1(model.RecordId, model.WorkoutId, model.ExerciseId, model.Exercise, model.EIMModel, model.Record, model.PreviousEntries, model.InProgress, model.Error, timerOptions_1), Cmd_none()];
        }
        case 7:
            return [new RecordModel$1(model.RecordId, model.WorkoutId, model.ExerciseId, model.Exercise, model.EIMModel, model.Record, model.PreviousEntries, false, exnToDisplayString(msg.fields[0]), model.TimerOptions), Cmd_none()];
        default: {
            const patternInput_2 = update_1(msg.fields[0], model.EIMModel);
            return [new RecordModel$1(model.RecordId, model.WorkoutId, model.ExerciseId, model.Exercise, patternInput_2[0], model.Record, model.PreviousEntries, model.InProgress, model.Error, model.TimerOptions), Cmd_map((arg_1) => (new RecordMsg$1(12, [arg_1])), patternInput_2[1])];
        }
    }
}

function AddEditExerciseRecordHandler$1__BuildForm(this$, withEventInfo, model, dispatch) {
    const form_1 = this$.buildForm(AddEditExerciseRecordHandler$1__buildFormBase(this$, withEventInfo, model, dispatch), RecordModel$1__IsEditing(model), model, model.Record, dispatch);
    FlexFormBase$1__set_State_Z3615493C(form_1, model.InProgress ? (new FlexFormState(1, [])) : ((model.Error != null) ? (new FlexFormState(2, [value_2(model.Error)])) : (new FlexFormState(0, []))));
    return form_1;
}

function AddEditExerciseRecordHandler$1__GetTimerOptions_Z524259C1(this$, exerciseId) {
    const result = localStorage.getItem(`timer:${exerciseId}`);
    if (isNullOrWhiteSpace(result)) {
        return defaultTimer;
    }
    else {
        return TimerOptions_Parse_Z721C83C5(result);
    }
}

function AddEditExerciseRecordHandler$1__SetTimerOptions(this$, exerciseId, timerOptions) {
    localStorage.setItem(`timer:${exerciseId}`, TimerOptions__Serialise(timerOptions));
}

function AddEditExerciseRecordHandler$1__ViewTimerOptions(this$, timerOptions, dispatch) {
    const form = FlexForm$1_$ctor_2ABDDB55((arg_1) => {
        dispatch(new RecordMsg$1(10, [arg_1]));
    }, (value) => {
    }, void 0);
    FlexFormFields$1__AddCheckbox_264AA22A(FlexFormFields$1__AddDurationSecsWithSeconds_ZAAC24(FlexFormFields$1__AddGroup_6DFDD678(FlexForm$1__get_Fields(form)), "DurationSecs", noValidation), "Start", noValidation);
    return form["Omnicv.Client.Common.Components.FlexForm.FlexFormBase`1.RenderFields2B595"](timerOptions);
}

function AddEditExerciseRecordHandler$1__CopyData(this$, model, dispatch, id) {
    const previous = tryFind((tupledArg) => equals_1(tupledArg[0].Id, id), model.PreviousEntries);
    if (previous == null) {
    }
    else {
        dispatch(new RecordMsg$1(1, [previous[1]]));
    }
}

function AddEditExerciseRecordHandler$1__View(this$, caption, session, model, dispatch) {
    const exerciseTitle = defaultArg(map((exercise) => exercise.Name, model.Exercise), " ");
    const title = RecordModel$1__IsEditing(model) ? (`Update ${caption}`) : (`Add ${caption}`);
    const form = AddEditExerciseRecordHandler$1__BuildForm(this$, true, model, dispatch);
    const children_6 = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Error: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => append(singleton_1(div(singleton(new Option(2, [model.InProgress])), toList(delay(() => append(singleton_1(container(empty(), toList(delay(() => append(singleton_1(h1(empty())(singleton(exerciseTitle))), delay(() => append(singleton_1(h2(singleton(new Option_1(6, [])))(singleton(title))), delay(() => ((model.WorkoutId != null) ? singleton_1(h4(singleton(new Option_1(6, [])))(singleton(`For workout ${value_2(model.WorkoutId)}`))) : empty_1()))))))))), delay(() => {
            const mainFormElement = FlexFormBase$1__Render_2B595(form, [model.EIMModel.EventInfo, model.Record]);
            let timerElements;
            const matchValue_1 = model.TimerOptions;
            timerElements = ((matchValue_1 != null) ? AddEditExerciseRecordHandler$1__ViewTimerOptions(this$, matchValue_1, dispatch) : empty());
            return singleton_1(createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(cons(mainFormElement, timerElements))),
            }));
        })))))), delay(() => {
            let displayRecord, children_4;
            if (!isEmpty(model.PreviousEntries)) {
                let entries;
                const list_1 = List_groupBy((tupledArg) => fromDateTime(tupledArg[0].EventTimestamp), model.PreviousEntries, {
                    Equals: equals_2,
                    GetHashCode: dateHash,
                });
                entries = collect((displayRecord = curry3(this$.displayRecord)(RecordModel$1__IsEditing(model) ? void 0 : ((arg_7) => {
                    AddEditExerciseRecordHandler$1__CopyData(this$, model, dispatch, arg_7);
                })), (tupledArg_1) => displayDailySingleExerciseRecords(session, uncurry2(displayRecord), tupledArg_1[0], tupledArg_1[1])), list_1);
                return singleton_1((children_4 = ofArray([h4(empty())(singleton("Previous entries")), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(entries)),
                })]), createElement("div", {
                    children: Interop_reactApi.Children.toArray(Array.from(children_4)),
                })));
            }
            else {
                return empty_1();
            }
        }))));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_6)),
    });
}

export function AddEditExerciseRecordHandler$1__buildFormBase(this$, withEventInfo, model, dispatch) {
    const form = FlexFormTuple$2_$ctor_5AE4FFF0((arg_1) => {
        dispatch(new RecordMsg$1(0, [arg_1]));
    }, (arg_3) => {
        dispatch(new RecordMsg$1(1, [arg_3]));
    }, (_arg_1) => {
        dispatch(new RecordMsg$1(8, []));
    }, () => {
        if (compare(model.ExerciseId, toInt64(fromInt32(0))) > 0) {
            Router_modifyLocation(new Route(42, [model.ExerciseId]));
        }
        else {
            Router_modifyLocation(new Route(32, []));
        }
    });
    if (withEventInfo) {
        addEventInfoWithSuggestionsToFlexForm(form, model.EIMModel.EventInfo, model.EIMModel.Position, model.PreviousEntries);
        if (!RecordModel$1__IsEditing(model)) {
            tryUpdateLocationFromSuggestions(() => getSuggestions(model.EIMModel.Position, model.PreviousEntries), model.EIMModel);
        }
    }
    return form;
}

function Handlers_noValidation(_arg) {
    return void 0;
}

function Handlers_buildRepsWithWeightRecordForm(form, _editing, model, record, dispatch) {
    FlexFormFields$1__AddPositiveInt_ZAAC24(FlexFormFields$1__AddPositiveFloat_1D6F52FB(FlexFormFields$1__AddGroup_6DFDD678(FlexFormTuple$2__get_FieldsSnd(form)), "Weight", validateWeight), "Repetitions", validateRepetitions);
    FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormTuple$2__get_FieldsSnd(form), "Notes", validateNotes);
    return form;
}

function Handlers_displayRecord(getInfo, copyDataFunc, session, data_, data__1) {
    const data = [data_, data__1];
    const patternInput = getInfo(data[1]);
    return displayExerciseRecordCommon(session, void 0, copyDataFunc, data[0], patternInput[0], patternInput[1], patternInput[2]);
}

const Handlers_repsWithWeight = AddEditExerciseRecordHandler$1_$ctor_23B8DC89(RepsWithWeightRecord_Default_Z524259C1, repsWithWeightRecordService, Handlers_buildRepsWithWeightRecordForm, (copyDataFunc, session, tupledArg) => Handlers_displayRecord(displayRepsWithWeight, copyDataFunc, session, tupledArg[0], tupledArg[1]));

function Handlers_buildRepsWithBodyWeightRecordForm(form, _editing, model, record, dispatch) {
    FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddPositiveInt_ZAAC24(FlexFormTuple$2__get_FieldsSnd(form), "Repetitions", validateRepetitions), "Notes", validateNotes);
    return form;
}

const Handlers_repsWithBodyWeight = AddEditExerciseRecordHandler$1_$ctor_23B8DC89(RepsWithBodyWeightRecord_Default_Z524259C1, repsWithBodyWeightRecordService, Handlers_buildRepsWithBodyWeightRecordForm, (copyDataFunc, session, tupledArg) => Handlers_displayRecord(displayRepsWithBodyWeightRecord, copyDataFunc, session, tupledArg[0], tupledArg[1]));

function Handlers_buildEnduranceRecordForm(form, _editing, model, record, dispatch) {
    FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddPositiveIntOpt_414042FF(FlexFormFields$1__AddDurationSecs_ZAAC24(FlexFormFields$1__AddPositiveFloat_1D6F52FB(FlexFormTuple$2__get_FieldsSnd(form), "DistanceKm", Handlers_noValidation), "DurationSec", Handlers_noValidation), "Calories", Handlers_noValidation), "Notes", validateNotes);
    return form;
}

const Handlers_endurance = AddEditExerciseRecordHandler$1_$ctor_23B8DC89(EnduranceRecord_Default_Z524259C1, enduranceRecordService, Handlers_buildEnduranceRecordForm, (copyDataFunc, session, tupledArg) => Handlers_displayRecord(displayEnduranceRecord, copyDataFunc, session, tupledArg[0], tupledArg[1]));

function Handlers_buildIntervalsDurationRecordForm(form, _editing, model, record, dispatch) {
    FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddDurationSecs_ZAAC24(FlexFormFields$1__AddDurationSecs_ZAAC24(FlexFormFields$1__AddPositiveInt_ZAAC24(FlexFormTuple$2__get_FieldsSnd(form), "NumExercises", Handlers_noValidation), "WorkDurationSec", Handlers_noValidation), "RestDurationSec", Handlers_noValidation), "Notes", validateNotes);
    return form;
}

const Handlers_intervalsDuration = AddEditExerciseRecordHandler$1_$ctor_23B8DC89(IntervalsDurationRecord_Default_Z524259C1, intervalsDurationRecordService, Handlers_buildIntervalsDurationRecordForm, (copyDataFunc, session, tupledArg) => Handlers_displayRecord(displayIntervalsDurationRecord, copyDataFunc, session, tupledArg[0], tupledArg[1]));

function Handlers_buildIntervalsRepsRecordForm(form, _editing, model, record, dispatch) {
    FlexFormFields$1__AddTextAreaOpt_Z44636648(FlexFormFields$1__AddDurationSecs_ZAAC24(FlexFormFields$1__AddPositiveInt_ZAAC24(FlexFormFields$1__AddPositiveInt_ZAAC24(FlexFormTuple$2__get_FieldsSnd(form), "NumExercises", Handlers_noValidation), "WorkRepetitions", Handlers_noValidation), "RestDurationSec", Handlers_noValidation), "Notes", validateNotes);
    return form;
}

const Handlers_intervalsReps = AddEditExerciseRecordHandler$1_$ctor_23B8DC89(IntervalsRepsRecord_Default_Z524259C1, intervalsRepsRecordService, Handlers_buildIntervalsRepsRecordForm, (copyDataFunc, session, tupledArg) => Handlers_displayRecord(displayIntervalsRepsRecord, copyDataFunc, session, tupledArg[0], tupledArg[1]));

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RepsWithWeightMsg", "RepsWithBodyWeightMsg", "EnduranceMsg", "IntervalsDurationMsg", "IntervalsRepsMsg"];
    }
}

export function Msg_$reflection() {
    return union_type("Omnicv.Client.Pages.Fitness.AddEditExerciseRecord.Msg", [], Msg, () => [[["Item", RecordMsg$1_$reflection(RepsWithWeightRecord_$reflection())]], [["Item", RecordMsg$1_$reflection(RepsWithBodyWeightRecord_$reflection())]], [["Item", RecordMsg$1_$reflection(EnduranceRecord_$reflection())]], [["Item", RecordMsg$1_$reflection(IntervalsDurationRecord_$reflection())]], [["Item", RecordMsg$1_$reflection(IntervalsRepsRecord_$reflection())]]]);
}

export class Model extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RepsWithWeightModel", "RepsWithBodyWeightModel", "EnduranceModel", "IntervalsDurationModel", "IntervalsRepsModel"];
    }
}

export function Model_$reflection() {
    return union_type("Omnicv.Client.Pages.Fitness.AddEditExerciseRecord.Model", [], Model, () => [[["Item", RecordModel$1_$reflection(RepsWithWeightRecord_$reflection())]], [["Item", RecordModel$1_$reflection(RepsWithBodyWeightRecord_$reflection())]], [["Item", RecordModel$1_$reflection(EnduranceRecord_$reflection())]], [["Item", RecordModel$1_$reflection(IntervalsDurationRecord_$reflection())]], [["Item", RecordModel$1_$reflection(IntervalsRepsRecord_$reflection())]]]);
}

function getLastSection(session, contentTypeId) {
    return last(ofArray_1(split(find((t) => (t.Id === contentTypeId), Session__SiteSettings(session).FitnessContentTypes).Name, ["."], void 0, 0))) + "Record";
}

function applyHandlerInit(withEventInfo, handler, toModel, toMsg, session, contentTypeId, id, isNew, workoutId, noFetch) {
    const patternInput = isNew ? AddEditExerciseRecordHandler$1__InitNew(handler, withEventInfo, session, contentTypeId, workoutId, id, noFetch) : AddEditExerciseRecordHandler$1__InitEdit(handler, withEventInfo, session, contentTypeId, id);
    return [toModel(patternInput[0]), Cmd_map(toMsg, patternInput[1])];
}

function initEither(withEventInfo, session, contentTypeId, id, isNew, workoutId, noFetch) {
    const lastSection = getLastSection(session, contentTypeId);
    switch (lastSection) {
        case "RepsWithWeightRecord":
            return applyHandlerInit(withEventInfo, Handlers_repsWithWeight, (arg) => (new Model(0, [arg])), (arg_1) => (new Msg(0, [arg_1])), session, contentTypeId, id, isNew, workoutId, noFetch);
        case "RepsWithBodyWeightRecord":
            return applyHandlerInit(withEventInfo, Handlers_repsWithBodyWeight, (arg_2) => (new Model(1, [arg_2])), (arg_3) => (new Msg(1, [arg_3])), session, contentTypeId, id, isNew, workoutId, noFetch);
        case "EnduranceRecord":
            return applyHandlerInit(withEventInfo, Handlers_endurance, (arg_4) => (new Model(2, [arg_4])), (arg_5) => (new Msg(2, [arg_5])), session, contentTypeId, id, isNew, workoutId, noFetch);
        case "IntervalsDurationRecord":
            return applyHandlerInit(withEventInfo, Handlers_intervalsDuration, (arg_6) => (new Model(3, [arg_6])), (arg_7) => (new Msg(3, [arg_7])), session, contentTypeId, id, isNew, workoutId, noFetch);
        case "IntervalsRepsRecord":
            return applyHandlerInit(withEventInfo, Handlers_intervalsReps, (arg_8) => (new Model(4, [arg_8])), (arg_9) => (new Msg(4, [arg_9])), session, contentTypeId, id, isNew, workoutId, noFetch);
        default:
            return toFail(printf("Unable to handle content type %s"))(lastSection);
    }
}

export function initNew(session, contentTypeId, id, workout) {
    return initEither(true, session, contentTypeId, id, true, map((arg) => toInt64(parse_2(arg, 511, false, 64)), workout), false);
}

export function initEdit(session, contentTypeId, id) {
    return initEither(true, session, contentTypeId, id, false, void 0, false);
}

export function update(session, msg, model) {
    let copyOfStruct_2, copyOfStruct_1_1, copyOfStruct_3, copyOfStruct_1_2, copyOfStruct_4, copyOfStruct_1_3, copyOfStruct_5, copyOfStruct_1_4, copyOfStruct, copyOfStruct_1;
    switch (msg.tag) {
        case 1:
            if (model.tag === 1) {
                const patternInput_1 = AddEditExerciseRecordHandler$1__Update(Handlers_repsWithBodyWeight, session, msg.fields[0], model.fields[0]);
                return [new Model(1, [patternInput_1[0]]), Cmd_map((arg_8) => (new Msg(1, [arg_8])), patternInput_1[1])];
            }
            else {
                const arg_9 = fullName((copyOfStruct_2 = msg, Msg_$reflection()));
                const arg_1_2 = fullName((copyOfStruct_1_1 = model, Model_$reflection()));
                return toFail(printf("Unexpected model type \'%s\' for message type \'%s\'"))(arg_9)(arg_1_2);
            }
        case 2:
            if (model.tag === 2) {
                const patternInput_2 = AddEditExerciseRecordHandler$1__Update(Handlers_endurance, session, msg.fields[0], model.fields[0]);
                return [new Model(2, [patternInput_2[0]]), Cmd_map((arg_13) => (new Msg(2, [arg_13])), patternInput_2[1])];
            }
            else {
                const arg_14 = fullName((copyOfStruct_3 = msg, Msg_$reflection()));
                const arg_1_3 = fullName((copyOfStruct_1_2 = model, Model_$reflection()));
                return toFail(printf("Unexpected model type \'%s\' for message type \'%s\'"))(arg_14)(arg_1_3);
            }
        case 3:
            if (model.tag === 3) {
                const patternInput_3 = AddEditExerciseRecordHandler$1__Update(Handlers_intervalsDuration, session, msg.fields[0], model.fields[0]);
                return [new Model(3, [patternInput_3[0]]), Cmd_map((arg_18) => (new Msg(3, [arg_18])), patternInput_3[1])];
            }
            else {
                const arg_19 = fullName((copyOfStruct_4 = msg, Msg_$reflection()));
                const arg_1_4 = fullName((copyOfStruct_1_3 = model, Model_$reflection()));
                return toFail(printf("Unexpected model type \'%s\' for message type \'%s\'"))(arg_19)(arg_1_4);
            }
        case 4:
            if (model.tag === 4) {
                const patternInput_4 = AddEditExerciseRecordHandler$1__Update(Handlers_intervalsReps, session, msg.fields[0], model.fields[0]);
                return [new Model(4, [patternInput_4[0]]), Cmd_map((arg_23) => (new Msg(4, [arg_23])), patternInput_4[1])];
            }
            else {
                const arg_24 = fullName((copyOfStruct_5 = msg, Msg_$reflection()));
                const arg_1_5 = fullName((copyOfStruct_1_4 = model, Model_$reflection()));
                return toFail(printf("Unexpected model type \'%s\' for message type \'%s\'"))(arg_24)(arg_1_5);
            }
        default:
            if (model.tag === 0) {
                const patternInput = AddEditExerciseRecordHandler$1__Update(Handlers_repsWithWeight, session, msg.fields[0], model.fields[0]);
                return [new Model(0, [patternInput[0]]), Cmd_map((arg_3) => (new Msg(0, [arg_3])), patternInput[1])];
            }
            else {
                const arg_4 = fullName((copyOfStruct = msg, Msg_$reflection()));
                const arg_1_1 = fullName((copyOfStruct_1 = model, Model_$reflection()));
                return toFail(printf("Unexpected model type \'%s\' for message type \'%s\'"))(arg_4)(arg_1_1);
            }
    }
}

export function getSetTimerMsg(msg) {
    let matchResult, dateTime, dateTime_1, dateTime_2, dateTime_3, dateTime_4;
    switch (msg.tag) {
        case 1: {
            if (msg.fields[0].tag === 11) {
                matchResult = 1;
                dateTime_1 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 5;
            }
            break;
        }
        case 2: {
            if (msg.fields[0].tag === 11) {
                matchResult = 2;
                dateTime_2 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 5;
            }
            break;
        }
        case 3: {
            if (msg.fields[0].tag === 11) {
                matchResult = 3;
                dateTime_3 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 5;
            }
            break;
        }
        case 4: {
            if (msg.fields[0].tag === 11) {
                matchResult = 4;
                dateTime_4 = msg.fields[0].fields[0];
            }
            else {
                matchResult = 5;
            }
            break;
        }
        default:
            if (msg.fields[0].tag === 11) {
                matchResult = 0;
                dateTime = msg.fields[0].fields[0];
            }
            else {
                matchResult = 5;
            }
    }
    switch (matchResult) {
        case 0:
            return dateTime;
        case 1:
            return dateTime_1;
        case 2:
            return dateTime_2;
        case 3:
            return dateTime_3;
        case 4:
            return dateTime_4;
        default:
            return void 0;
    }
}

export function mapModelToEIMMsg(model, subMsg) {
    switch (model.tag) {
        case 1:
            return new Msg(1, [new RecordMsg$1(12, [subMsg])]);
        case 2:
            return new Msg(2, [new RecordMsg$1(12, [subMsg])]);
        case 3:
            return new Msg(3, [new RecordMsg$1(12, [subMsg])]);
        case 4:
            return new Msg(4, [new RecordMsg$1(12, [subMsg])]);
        default:
            return new Msg(0, [new RecordMsg$1(12, [subMsg])]);
    }
}

export function view(session, model, dispatch) {
    switch (model.tag) {
        case 1:
            return AddEditExerciseRecordHandler$1__View(Handlers_repsWithBodyWeight, "reps with body weight", session, model.fields[0], (arg_11) => {
                dispatch(new Msg(1, [arg_11]));
            });
        case 2:
            return AddEditExerciseRecordHandler$1__View(Handlers_endurance, "endurance", session, model.fields[0], (arg_17) => {
                dispatch(new Msg(2, [arg_17]));
            });
        case 3:
            return AddEditExerciseRecordHandler$1__View(Handlers_intervalsDuration, "intervals / HIIT", session, model.fields[0], (arg_23) => {
                dispatch(new Msg(3, [arg_23]));
            });
        case 4:
            return AddEditExerciseRecordHandler$1__View(Handlers_intervalsReps, "reps in intervals", session, model.fields[0], (arg_29) => {
                dispatch(new Msg(4, [arg_29]));
            });
        default:
            return AddEditExerciseRecordHandler$1__View(Handlers_repsWithWeight, "reps with weight", session, model.fields[0], (arg_5) => {
                dispatch(new Msg(0, [arg_5]));
            });
    }
}

function ExerciseRecordsMany_msgFromExerciseRecord(exerciseId, eventInfo, record) {
    switch (record.tag) {
        case 1: {
            const record_2 = record.fields[0];
            return new Msg(1, [new RecordMsg$1(6, [singleton([eventInfo, new RepsWithBodyWeightRecord(record_2.Id, exerciseId, record_2.WorkoutRecordId, record_2.Repetitions, record_2.Notes)])])]);
        }
        case 2: {
            const record_3 = record.fields[0];
            return new Msg(2, [new RecordMsg$1(6, [singleton([eventInfo, new EnduranceRecord(record_3.Id, exerciseId, record_3.WorkoutRecordId, record_3.DurationSec, record_3.DistanceKm, record_3.Calories, record_3.Notes)])])]);
        }
        case 3: {
            const record_4 = record.fields[0];
            return new Msg(3, [new RecordMsg$1(6, [singleton([eventInfo, new IntervalsDurationRecord(record_4.Id, exerciseId, record_4.WorkoutRecordId, record_4.NumExercises, record_4.WorkDurationSec, record_4.RestDurationSec, record_4.Notes)])])]);
        }
        case 4: {
            const record_5 = record.fields[0];
            return new Msg(4, [new RecordMsg$1(6, [singleton([eventInfo, new IntervalsRepsRecord(record_5.Id, exerciseId, record_5.WorkoutRecordId, record_5.NumExercises, record_5.WorkRepetitions, record_5.RestDurationSec, record_5.Notes)])])]);
        }
        default: {
            const record_1 = record.fields[0];
            return new Msg(0, [new RecordMsg$1(6, [singleton([eventInfo, new RepsWithWeightRecord(record_1.Id, exerciseId, record_1.WorkoutRecordId, record_1.Repetitions, record_1.Weight, record_1.Notes)])])]);
        }
    }
}

function ExerciseRecordsMany_modelToExerciseRecord(model) {
    switch (model.tag) {
        case 1:
            return new ExerciseRecord(1, [model.fields[0].Record]);
        case 2:
            return new ExerciseRecord(2, [model.fields[0].Record]);
        case 3:
            return new ExerciseRecord(3, [model.fields[0].Record]);
        case 4:
            return new ExerciseRecord(4, [model.fields[0].Record]);
        default:
            return new ExerciseRecord(0, [model.fields[0].Record]);
    }
}

function ExerciseRecordsMany_modelToEventInfo(model) {
    switch (model.tag) {
        case 1:
            return model.fields[0].EIMModel.EventInfo;
        case 2:
            return model.fields[0].EIMModel.EventInfo;
        case 3:
            return model.fields[0].EIMModel.EventInfo;
        case 4:
            return model.fields[0].EIMModel.EventInfo;
        default:
            return model.fields[0].EIMModel.EventInfo;
    }
}

export class ExerciseRecordsMany_ModelMany extends Record {
    constructor(WorkoutId, Data, Models, InProgress, Error$) {
        super();
        this.WorkoutId = WorkoutId;
        this.Data = Data;
        this.Models = Models;
        this.InProgress = InProgress;
        this.Error = Error$;
    }
}

export function ExerciseRecordsMany_ModelMany_$reflection() {
    return record_type("Omnicv.Client.Pages.Fitness.AddEditExerciseRecord.ExerciseRecordsMany.ModelMany", [], ExerciseRecordsMany_ModelMany, () => [["WorkoutId", int64_type], ["Data", option_type(WorkoutCircuitData_$reflection())], ["Models", array_type(Model_$reflection())], ["InProgress", bool_type], ["Error", option_type(string_type)]]);
}

export function ExerciseRecordsMany_ModelMany__GetEventInfo(this$) {
    return ExerciseRecordsMany_modelToEventInfo(this$.Models[0]);
}

export function ExerciseRecordsMany_ModelMany__GetRecords(this$) {
    return map_1(ExerciseRecordsMany_modelToExerciseRecord, ofArray(this$.Models));
}

export class ExerciseRecordsMany_MsgMany extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SingleRecordMsg", "FetchSuccess", "Failure", "Submit", "SubmitSuccess"];
    }
}

export function ExerciseRecordsMany_MsgMany_$reflection() {
    return union_type("Omnicv.Client.Pages.Fitness.AddEditExerciseRecord.ExerciseRecordsMany.MsgMany", [], ExerciseRecordsMany_MsgMany, () => [[["Item1", Msg_$reflection()], ["Item2", int32_type]], [["Item", WorkoutCircuitData_$reflection()]], [["Item", class_type("System.Exception")]], [], [["Item", unit_type]]]);
}

export function ExerciseRecordsMany_mapModelToEIMMsg(model, subMsg) {
    return new ExerciseRecordsMany_MsgMany(0, [mapModelToEIMMsg(model.Models[0], subMsg), 0]);
}

function ExerciseRecordsMany_initNewRecordModel(session, records, workout, index, exercise) {
    let matchValue, subMsg;
    const patternInput = initEither(index === 0, session, exercise.ContentTypeId, exercise.Id, true, workout.Id, true);
    const cmd = patternInput[1];
    return [patternInput[0], Cmd_map((msg_1) => (new ExerciseRecordsMany_MsgMany(0, [msg_1, index])), (matchValue = tryFind((tupledArg) => equals_1(flex_form.getExerciseId(tupledArg[1]), exercise.Id), records), (matchValue == null) ? cmd : ((subMsg = ExerciseRecordsMany_msgFromExerciseRecord(exercise.Id, matchValue[0], matchValue[1]), append_1(cmd, singleton((dispatch) => {
        dispatch(subMsg);
    }))))))];
}

export function ExerciseRecordsMany_initNewMany(session, workoutId) {
    return [new ExerciseRecordsMany_ModelMany(workoutId, void 0, [], true, void 0), Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, curry2(fitnessService.GetWorkoutCircuitData)(Session__GetCurrentProfileId(session)), workoutId, (arg) => (new ExerciseRecordsMany_MsgMany(1, [arg])), (arg_1) => (new ExerciseRecordsMany_MsgMany(2, [arg_1])))];
}

export function ExerciseRecordsMany_update(session, msg, model) {
    let eventInfo;
    switch (msg.tag) {
        case 1: {
            const result = msg.fields[0];
            const pairs = mapIndexed((index_1, exercise) => ExerciseRecordsMany_initNewRecordModel(session, result.ExerciseRecords, result.Workout, index_1, exercise), result.Exercises);
            return [new ExerciseRecordsMany_ModelMany(model.WorkoutId, result, toArray(map_1((tuple) => tuple[0], pairs)), false, model.Error), Cmd_batch(map_1((tuple_1) => tuple_1[1], pairs))];
        }
        case 3:
            return [new ExerciseRecordsMany_ModelMany(model.WorkoutId, model.Data, model.Models, true, model.Error), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, (eventInfo = ExerciseRecordsMany_ModelMany__GetEventInfo(model), (records) => {
                const profileId = Session__GetCurrentProfileId(session) | 0;
                return fitnessService.AddExerciseRecords((Session__GetActiveWorkoutRecord(session) != null) ? (new WorkoutRecordType(0, [])) : (new WorkoutRecordType(1, [model.WorkoutId])), new EventInfo(eventInfo.Id, 0, profileId, eventInfo.EventTimestamp, eventInfo.CreatedAt, eventInfo.UpdatedAt, eventInfo.PlaceId, eventInfo.PlaceName, eventInfo.Latitude, eventInfo.Longitude, eventInfo.LocationTz, eventInfo.LocationTzOffsetMin, eventInfo.ClientTz, eventInfo.ClientTzOffsetMin), records);
            }), ExerciseRecordsMany_ModelMany__GetRecords(model), () => (new ExerciseRecordsMany_MsgMany(4, [void 0])), (arg_1) => (new ExerciseRecordsMany_MsgMany(2, [arg_1])))];
        case 4:
            return [new ExerciseRecordsMany_ModelMany(model.WorkoutId, model.Data, model.Models, false, model.Error), Router_newUrl(new Route(38, [model.WorkoutId]))];
        case 2:
            return [new ExerciseRecordsMany_ModelMany(model.WorkoutId, model.Data, model.Models, false, exnToDisplayString(msg.fields[0])), Cmd_none()];
        default: {
            const index = msg.fields[1] | 0;
            const patternInput = update(session, msg.fields[0], model.Models[index]);
            return [new ExerciseRecordsMany_ModelMany(model.WorkoutId, model.Data, updateAt(index, patternInput[0], model.Models), model.InProgress, model.Error), Cmd_map((msg_1) => (new ExerciseRecordsMany_MsgMany(0, [msg_1, index])), patternInput[1])];
        }
    }
}

function ExerciseRecordsMany_renderModelForm(caption, withEventInfo, model, form) {
    const children_2 = toList(delay(() => {
        let matchValue;
        return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Error: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => singleton_1(div(empty(), toList(delay(() => {
            const heading = container(empty(), singleton(h3(singleton(new Option_1(6, [])))(singleton(caption))));
            const recordRendering = FlexFormFields$1__Render_2B595(FlexFormTuple$2__get_FieldsSnd(form), model.Record);
            const elements = withEventInfo ? append_1(FlexFormFields$1__Render_2B595(FlexFormTuple$2__get_FieldsFst(form), model.EIMModel.EventInfo), cons(heading, recordRendering)) : cons(heading, recordRendering);
            return singleton_1(createElement("div", {
                children: Interop_reactApi.Children.toArray(Array.from(elements)),
            }));
        }))))));
    }));
    return createElement("div", {
        children: Interop_reactApi.Children.toArray(Array.from(children_2)),
    });
}

function ExerciseRecordsMany_canSubmitModelForm(withEventInfo, model, form) {
    if (withEventInfo && !FlexFormFields$1__CanSubmit_2B595(FlexFormTuple$2__get_FieldsFst(form), model.EIMModel.EventInfo)) {
        return false;
    }
    else {
        return FlexFormFields$1__CanSubmit_2B595(FlexFormTuple$2__get_FieldsSnd(form), model.Record);
    }
}

function ExerciseRecordsMany_getFormletHandle(handler, caption, withEventInfo, model, dispatch) {
    const form = AddEditExerciseRecordHandler$1__BuildForm(handler, withEventInfo, model, dispatch);
    return new Formlet$1(() => ExerciseRecordsMany_renderModelForm(caption, withEventInfo, model, form), () => ExerciseRecordsMany_canSubmitModelForm(withEventInfo, model, form));
}

function ExerciseRecordsMany_getFormlet(exercise, withEventInfo, model, dispatch) {
    const name = exercise.Name;
    switch (model.tag) {
        case 1:
            return ExerciseRecordsMany_getFormletHandle(Handlers_repsWithBodyWeight, `${name}: reps with body weight`, withEventInfo, model.fields[0], (arg_3) => {
                dispatch(new Msg(1, [arg_3]));
            });
        case 2:
            return ExerciseRecordsMany_getFormletHandle(Handlers_endurance, `${name}: endurance`, withEventInfo, model.fields[0], (arg_5) => {
                dispatch(new Msg(2, [arg_5]));
            });
        case 3:
            return ExerciseRecordsMany_getFormletHandle(Handlers_intervalsDuration, `${name}: intervals / HIIT`, withEventInfo, model.fields[0], (arg_7) => {
                dispatch(new Msg(3, [arg_7]));
            });
        case 4:
            return ExerciseRecordsMany_getFormletHandle(Handlers_intervalsReps, `${name}: reps in intervals`, withEventInfo, model.fields[0], (arg_9) => {
                dispatch(new Msg(4, [arg_9]));
            });
        default:
            return ExerciseRecordsMany_getFormletHandle(Handlers_repsWithWeight, `${name}: reps with weight`, withEventInfo, model.fields[0], (arg_1) => {
                dispatch(new Msg(0, [arg_1]));
            });
    }
}

export function ExerciseRecordsMany_view(model, dispatch) {
    let list_1;
    if (model.Data != null) {
        const form = FlexFormWithFormlets$1_$ctor_Z7DF2018A(mapIndexed((index, tupledArg) => ExerciseRecordsMany_getFormlet(tupledArg[0], index === 0, tupledArg[1], (msg) => {
            dispatch(new ExerciseRecordsMany_MsgMany(0, [msg, index]));
        }), (list_1 = ofArray(model.Models), zip(value_2(model.Data).Exercises, list_1))), () => {
            dispatch(new ExerciseRecordsMany_MsgMany(3, []));
        }, () => {
            Router_modifyLocation(new Route(38, [model.WorkoutId]));
        });
        FlexFormBase$1__set_State_Z3615493C(form, model.InProgress ? (new FlexFormState(1, [])) : ((model.Error != null) ? (new FlexFormState(2, [value_2(model.Error)])) : (new FlexFormState(0, []))));
        const children = append_1(toList(delay(() => {
            let matchValue;
            return append((matchValue = model.Error, (matchValue != null) ? singleton_1(closeable(`Error: ${matchValue}`, new Class(4, []))) : singleton_1(defaultOf())), delay(() => append(singleton_1(h1(empty())(singleton("Populate all exercises for workout"))), delay(() => append(singleton_1(h2(singleton(new Option_1(6, [])))(singleton(value_2(model.Data).Workout.Name))), delay(() => singleton_1(FlexFormBase$1__Render_2B595(form, void 0))))))));
        })), displayWorkoutRecords(value_2(model.Data)));
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(Array.from(children)),
        });
    }
    else {
        return createElement("div", {
            children: Interop_reactApi.Children.toArray(["Loading data..."]),
        });
    }
}

