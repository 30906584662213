import { Fa_IconOption } from "./fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { EntryDescriptor, Actions, ModuleDescriptor, IconType } from "./Omnicv.Client.Common/ClientModule.js";
import { Route } from "./Omnicv.Client.Common/Router.js";
import { ofArray } from "./fable_modules/fable-library.4.1.4/List.js";
import { PhysicalModule_$reflection, PhysicalModule } from "./Omnicv.Shared/PhysicalAPI.js";
import { class_type } from "./fable_modules/fable-library.4.1.4/Reflection.js";

export class PhysicalClientModule extends PhysicalModule {
    constructor() {
        super();
    }
    Descriptor() {
        return new ModuleDescriptor(new IconType(new Fa_IconOption(11, ["fas fa-ambulance"])), (_arg) => (new Route(18, [])));
    }
    EntryDescriptors() {
        return ofArray([new EntryDescriptor("WeightRecord", "Weight", "Track your weight", new IconType(new Fa_IconOption(11, ["fas fa-balance-scale-left"])), new Actions((_arg) => (new Route(21, [])), (arg) => (new Route(22, [arg])), void 0, new Route(20, []))), new EntryDescriptor("BloodPressureRecord", "Blood Pressure", "Track your blood pressure", new IconType(new Fa_IconOption(11, ["fas fa-heartbeat"])), new Actions((_arg_1) => (new Route(25, [])), (arg_1) => (new Route(26, [arg_1])), void 0, void 0)), new EntryDescriptor("BodilyFunctionsRecord", "Elimination", "Track your bodily functions", new IconType(new Fa_IconOption(11, ["fas fa-toilet"])), new Actions((_arg_2) => (new Route(23, [])), (arg_2) => (new Route(24, [arg_2])), void 0, new Route(19, []))), new EntryDescriptor("TemperatureRecord", "Temperature", "Track your temperature", new IconType(new Fa_IconOption(11, ["fas fa-temperature-high"])), new Actions((_arg_3) => (new Route(27, [])), (arg_3) => (new Route(28, [arg_3])), void 0, void 0))]);
    }
}

export function PhysicalClientModule_$reflection() {
    return class_type("Omnicv.Client.Physical.PhysicalClientModule", void 0, PhysicalClientModule, PhysicalModule_$reflection());
}

export function PhysicalClientModule_$ctor() {
    return new PhysicalClientModule();
}

