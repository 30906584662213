import { Union, Record } from "../fable_modules/fable-library.4.1.4/Types.js";
import { union_type, record_type, int64_type, option_type, lambda_type, string_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";
import { Router_modifyLocation, Route_$reflection } from "./Router.js";
import { Fa_i, Fa_IconOption_$reflection } from "../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { filter, map, empty, ofArray, singleton } from "../fable_modules/fable-library.4.1.4/List.js";
import { list as list_3, Option, button } from "../fable_modules/Fulma.3.0.0/Elements/Button.fs.js";
import { Color_IColor, Size_ISize } from "../fable_modules/Fulma.3.0.0/Common.fs.js";
import { icon as icon_1 } from "../fable_modules/Fulma.3.0.0/Elements/Icon.fs.js";
import * as react from "react";
import { value as value_1 } from "../fable_modules/fable-library.4.1.4/Option.js";

export class Actions extends Record {
    constructor(New, Edit, View, ViewAll) {
        super();
        this.New = New;
        this.Edit = Edit;
        this.View = View;
        this.ViewAll = ViewAll;
    }
}

export function Actions_$reflection() {
    return record_type("Omnicv.Client.Common.ClientModule.Actions", [], Actions, () => [["New", option_type(lambda_type(string_type, Route_$reflection()))], ["Edit", option_type(lambda_type(int64_type, Route_$reflection()))], ["View", option_type(lambda_type(int64_type, Route_$reflection()))], ["ViewAll", option_type(Route_$reflection())]]);
}

export class IconType extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["FontAwesome"];
    }
}

export function IconType_$reflection() {
    return union_type("Omnicv.Client.Common.ClientModule.IconType", [], IconType, () => [[["Item", Fa_IconOption_$reflection()]]]);
}

export function IconType__ToIcon(this$) {
    return Fa_i(singleton(this$.fields[0]), []);
}

export class ModuleDescriptor extends Record {
    constructor(Icon, View) {
        super();
        this.Icon = Icon;
        this.View = View;
    }
}

export function ModuleDescriptor_$reflection() {
    return record_type("Omnicv.Client.Common.ClientModule.ModuleDescriptor", [], ModuleDescriptor, () => [["Icon", IconType_$reflection()], ["View", option_type(lambda_type(string_type, Route_$reflection()))]]);
}

export class EntryDescriptor extends Record {
    constructor(Name, Caption, Details, Icon, Actions) {
        super();
        this.Name = Name;
        this.Caption = Caption;
        this.Details = Details;
        this.Icon = Icon;
        this.Actions = Actions;
    }
}

export function EntryDescriptor_$reflection() {
    return record_type("Omnicv.Client.Common.ClientModule.EntryDescriptor", [], EntryDescriptor, () => [["Name", string_type], ["Caption", string_type], ["Details", string_type], ["Icon", IconType_$reflection()], ["Actions", Actions_$reflection()]]);
}

export function EntryDescriptor__GetButton(this$, colour, isDisabled, onClick) {
    let children;
    const children_4 = [(children = [button(ofArray([new Option(0, [colour]), new Option(16, [isDisabled]), new Option(18, [onClick]), new Option(1, [new Size_ISize(2, [])])]), singleton(icon_1(empty(), singleton(IconType__ToIcon(this$.Icon)))))], react.createElement("div", {}, ...children)), react.createElement("div", {
        className: "button-caption",
    }, this$.Caption)];
    return react.createElement("div", {
        className: "button-wrapper",
    }, ...children_4);
}

export function getModuleNewButtons(clientModule, isDisabled, param) {
    const children = [list_3(empty(), map(value_1, filter((option) => (option != null), map((des) => {
        const matchValue = des.Actions.New;
        if (matchValue != null) {
            const route = matchValue;
            return EntryDescriptor__GetButton(des, new Color_IColor(6, []), isDisabled, (_arg) => {
                Router_modifyLocation(route(param));
            });
        }
        else {
            return void 0;
        }
    }, clientModule.EntryDescriptors()))))];
    return react.createElement("div", {
        className: "block",
    }, ...children);
}

export function getModuleViewAllButtons(clientModule, isDisabled) {
    const children = [list_3(empty(), map(value_1, filter((option) => (option != null), map((des) => {
        const matchValue = des.Actions.ViewAll;
        if (matchValue != null) {
            const route = matchValue;
            return EntryDescriptor__GetButton(des, new Color_IColor(5, []), isDisabled, (_arg) => {
                Router_modifyLocation(route);
            });
        }
        else {
            return void 0;
        }
    }, clientModule.EntryDescriptors()))))];
    return react.createElement("div", {
        className: "block",
    }, ...children);
}

