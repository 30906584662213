import { Common_GenericOptions__ToReactElement_Z2B8EEFE, Common_parseOptions, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7 } from "../../Common.fs.js";
import * as react from "react";
import { keyValueList } from "../../../fable-library.4.1.4/MapUtil.js";
import { HTMLAttr } from "../../../Fable.React.9.3.0/Fable.React.Props.fs.js";
import { singleton } from "../../../fable-library.4.1.4/List.js";

/**
 * Generate <label class="checkbox"></label>
 */
export function checkbox(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "checkbox"), (props, children_1) => react.createElement("label", keyValueList(props, 1), ...children_1), children);
}

/**
 * Generate <input type="checkbox" class="checkbox"/>
 */
export function input(options) {
    return Common_GenericOptions__ToReactElement_Z2B8EEFE(Common_GenericOptions_Parse_Z4D1A7726(options, Common_parseOptions, "checkbox", singleton(new HTMLAttr(159, ["checkbox"]))), (props) => react.createElement("input", keyValueList(props, 1)));
}

