import { class_type } from "../fable-library.4.1.4/Reflection.js";
import { unwrap, defaultArg } from "../fable-library.4.1.4/Option.js";
import { toList } from "../fable-library.4.1.4/Seq.js";
import { mapIndexed } from "../fable-library.4.1.4/Array.js";
import { MarkdownRangeModule_zero, MarkdownRange } from "./Range.fs.js";
import { isNullOrWhiteSpace, replace, split } from "../fable-library.4.1.4/String.js";
import { empty, skipWhile, ofSeq } from "../fable-library.4.1.4/List.js";
import { ParsingContext, parseParagraphs } from "./MarkdownParser.fs.js";
import { formatMarkdown } from "./HtmlFormatting.fs.js";
import { FableTextWriter_$ctor } from "./MarkdownUtils.fs.js";
import { disposeSafe } from "../fable-library.4.1.4/Util.js";
import { toString } from "../fable-library.4.1.4/Types.js";
import { formatAsMd } from "./MarkdownFormatting.fs.js";

export class MarkdownDocument {
    constructor(paragraphs, links) {
        this.paragraphs = paragraphs;
        this.links = links;
    }
}

export function MarkdownDocument_$reflection() {
    return class_type("Fable.Formatting.Markdown.MarkdownDocument", void 0, MarkdownDocument);
}

export function MarkdownDocument_$ctor_Z7AE59E28(paragraphs, links) {
    return new MarkdownDocument(paragraphs, links);
}

/**
 * Returns a list of paragraphs in the document
 */
export function MarkdownDocument__get_Paragraphs(x) {
    return x.paragraphs;
}

/**
 * Returns a dictionary containing explicitly defined links
 */
export function MarkdownDocument__get_DefinedLinks(x) {
    return x.links;
}

export class Markdown {
    constructor() {
    }
}

export function Markdown_$reflection() {
    return class_type("Fable.Formatting.Markdown.Markdown", void 0, Markdown);
}

export function Markdown_$ctor() {
    return new Markdown();
}

/**
 * Parse the specified text into a MarkdownDocument. Line breaks in the
 * inline HTML (etc.) will be stored using the specified string.
 */
export function Markdown_Parse_7B74E5F5(text, newline, parseOptions) {
    const newline_1 = defaultArg(newline, "\n");
    const parseOptions_1 = defaultArg(parseOptions, 0) | 0;
    const lines = toList(mapIndexed((i, s) => [s, new MarkdownRange(i + 1, 0, i + 1, s.length)], split(replace(text, "\r\n", "\n"), ["\n", "\r"])));
    const links = new Map([]);
    return MarkdownDocument_$ctor_Z7AE59E28(ofSeq(parseParagraphs(new ParsingContext(links, newline_1, true, MarkdownRangeModule_zero, parseOptions_1), skipWhile((tupledArg) => isNullOrWhiteSpace(tupledArg[0]), lines))), links);
}

/**
 * Transform the provided MarkdownDocument into HTML
 * format and write the result to a given writer.
 */
export function Markdown_WriteHtml_27D81AD6(doc, writer, newline, substitutions, crefResolver) {
    const newline_1 = defaultArg(newline, "\n");
    const substitutions_1 = defaultArg(substitutions, empty());
    const crefResolver_1 = defaultArg(crefResolver, (_arg) => void 0);
    formatMarkdown(writer, false, false, MarkdownDocument__get_DefinedLinks(doc), substitutions_1, newline_1, crefResolver_1, MarkdownDocument__get_Paragraphs(doc));
}

/**
 * Transform Markdown text into HTML format. The result
 * will be written to the provided TextWriter.
 */
export function Markdown_WriteHtml_Z7FF308E(markdownText, writer, newline, substitutions, crefResolver) {
    Markdown_WriteHtml_27D81AD6(Markdown_Parse_7B74E5F5(markdownText, newline), writer, unwrap(newline), unwrap(substitutions), unwrap(crefResolver));
}

/**
 * Transform the provided MarkdownDocument into HTML
 * format and return the result as a string.
 */
export function Markdown_ToHtml_1A9D76FF(doc, newline, substitutions, crefResolver) {
    const wr = FableTextWriter_$ctor();
    try {
        Markdown_WriteHtml_27D81AD6(doc, wr, unwrap(newline), unwrap(substitutions), unwrap(crefResolver));
        return toString(wr);
    }
    finally {
        disposeSafe(wr);
    }
}

/**
 * Transform Markdown document into HTML format.
 * The result will be returned as a string.
 */
export function Markdown_ToHtml_1BFEAEDB(markdownText, newline, substitutions, crefResolver) {
    return Markdown_ToHtml_1A9D76FF(Markdown_Parse_7B74E5F5(markdownText, newline), unwrap(newline), unwrap(substitutions), unwrap(crefResolver));
}

/**
 * Transform the provided MarkdownDocument into Md and return the result as a string.
 */
export function Markdown_ToMd_1A9D76FF(doc, newline, substitutions, crefResolver) {
    const newline_1 = defaultArg(newline, "\n");
    const substitutions_1 = defaultArg(substitutions, empty());
    const crefResolver_1 = defaultArg(crefResolver, (_arg) => void 0);
    return formatAsMd(MarkdownDocument__get_DefinedLinks(doc), substitutions_1, newline_1, crefResolver_1, MarkdownDocument__get_Paragraphs(doc));
}

