import { Union, Record } from "../fable-library.4.1.4/Types.js";
import { Error$ as Error$_1, Error$_$reflection } from "./Error.fs.js";
import { string_type, lambda_type, union_type, tuple_type, list_type, record_type, bool_type, option_type } from "../fable-library.4.1.4/Reflection.js";
import { Result_MapError, Result_Map, FSharpResult$2 } from "../fable-library.4.1.4/Choice.js";
import { cons, append as append_1, map as map_1, singleton, empty } from "../fable-library.4.1.4/List.js";
import { Result_andThen } from "./Extensions.fs.js";
import { some, map as map_2, defaultArg } from "../fable-library.4.1.4/Option.js";
import { equals } from "../fable-library.4.1.4/Util.js";
import { Field$3 } from "./Field.fs.js";

export class FilledField$1 extends Record {
    constructor(State, Error$, IsDisabled) {
        super();
        this.State = State;
        this.Error = Error$;
        this.IsDisabled = IsDisabled;
    }
}

export function FilledField$1_$reflection(gen0) {
    return record_type("Fable.Form.Base.FilledField`1", [gen0], FilledField$1, () => [["State", gen0], ["Error", option_type(Error$_$reflection())], ["IsDisabled", bool_type]]);
}

export class FilledForm$2 extends Record {
    constructor(Fields, Result, IsEmpty) {
        super();
        this.Fields = Fields;
        this.Result = Result;
        this.IsEmpty = IsEmpty;
    }
}

export function FilledForm$2_$reflection(gen0, gen1) {
    return record_type("Fable.Form.Base.FilledForm`2", [gen0, gen1], FilledForm$2, () => [["Fields", list_type(FilledField$1_$reflection(gen1))], ["Result", union_type("Microsoft.FSharp.Core.FSharpResult`2", [gen0, tuple_type(Error$_$reflection(), list_type(Error$_$reflection()))], FSharpResult$2, () => [[["ResultValue", gen0]], [["ErrorValue", tuple_type(Error$_$reflection(), list_type(Error$_$reflection()))]]])], ["IsEmpty", bool_type]]);
}

export class Form$3 extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Form"];
    }
}

export function Form$3_$reflection(gen0, gen1, gen2) {
    return union_type("Fable.Form.Base.Form`3", [gen0, gen1, gen2], Form$3, () => [[["Item", lambda_type(gen0, FilledForm$2_$reflection(gen1, gen2))]]]);
}

export class FieldConfig$4 extends Record {
    constructor(Parser, Value, Update, Error$, Attributes) {
        super();
        this.Parser = Parser;
        this.Value = Value;
        this.Update = Update;
        this.Error = Error$;
        this.Attributes = Attributes;
    }
}

export function FieldConfig$4_$reflection(gen0, gen1, gen2, gen3) {
    return record_type("Fable.Form.Base.FieldConfig`4", [gen0, gen1, gen2, gen3], FieldConfig$4, () => [["Parser", lambda_type(gen1, union_type("Microsoft.FSharp.Core.FSharpResult`2", [gen3, string_type], FSharpResult$2, () => [[["ResultValue", gen3]], [["ErrorValue", string_type]]]))], ["Value", lambda_type(gen2, gen1)], ["Update", lambda_type(gen1, lambda_type(gen2, gen2))], ["Error", lambda_type(gen2, option_type(string_type))], ["Attributes", gen0]]);
}

export class CustomField$2 extends Record {
    constructor(State, Result, IsEmpty) {
        super();
        this.State = State;
        this.Result = Result;
        this.IsEmpty = IsEmpty;
    }
}

export function CustomField$2_$reflection(gen0, gen1) {
    return record_type("Fable.Form.Base.CustomField`2", [gen0, gen1], CustomField$2, () => [["State", gen1], ["Result", union_type("Microsoft.FSharp.Core.FSharpResult`2", [gen0, tuple_type(Error$_$reflection(), list_type(Error$_$reflection()))], FSharpResult$2, () => [[["ResultValue", gen0]], [["ErrorValue", tuple_type(Error$_$reflection(), list_type(Error$_$reflection()))]]])], ["IsEmpty", bool_type]]);
}

/**
 * Create a form that always succeeds when filled.
 * 
 * Note: You can choose to discard one of the function argument. The classic example for that is when dealing with a <c>repeatPasswordField</c>
 * 
 * <para>
 * <code lang="fsharp">
 * Form.succeed (fun password _ -> password )
 * |> Form.append passwordField
 * |> Form.append repeatPasswordField
 * </code>
 * </para>
 */
export function succeed(output) {
    return new Form$3((_arg) => (new FilledForm$2(empty(), new FSharpResult$2(0, [output]), true)));
}

/**
 * Fill a form with some <c>'Values</c>
 */
export function fill(_arg) {
    return _arg.fields[0];
}

/**
 * Create a custom field
 */
export function custom(fillField) {
    return new Form$3((values) => {
        let matchValue;
        const filled = fillField(values);
        return new FilledForm$2(singleton(new FilledField$1(filled.State, filled.IsEmpty ? (new Error$_1(0, [])) : ((matchValue = filled.Result, (matchValue.tag === 1) ? matchValue.fields[0][0] : void 0)), false)), filled.Result, filled.IsEmpty);
    });
}

/**
 * Build a form that depends on its own <c>'Values</c>
 * 
 * This is useful when a field need to checks it's value against another field value.
 * 
 * The classic example for using <c>meta</c> is when dealing with a repeat password field.
 */
export function meta(fn) {
    return new Form$3((values) => fill(fn(values))(values));
}

/**
 * Transform the values of a form.
 * 
 * This function is useful when you want to re-use existing form or nest them.
 */
export function mapValues(fn, form) {
    let f2;
    return new Form$3((f2 = fill(form), (arg) => f2(fn(arg))));
}

/**
 * Apply the given function to all the field
 */
export function mapField(fn, form) {
    return new Form$3((values) => {
        const filled = fill(form)(values);
        return new FilledForm$2(map_1((filledField) => (new FilledField$1(fn(filledField.State), filledField.Error, filledField.IsDisabled)), filled.Fields), filled.Result, filled.IsEmpty);
    });
}

/**
 * Append a form to another one while <b>capturing</b> the output of the first one
 */
export function append(newForm, currentForm) {
    return new Form$3((values) => {
        const filledNew = fill(newForm)(values);
        const filledCurrent = fill(currentForm)(values);
        const fields = append_1(filledCurrent.Fields, filledNew.Fields);
        const isEmpty = filledCurrent.IsEmpty && filledNew.IsEmpty;
        const matchValue = filledCurrent.Result;
        if (matchValue.tag === 1) {
            const otherErrors = matchValue.fields[0][1];
            const firstError = matchValue.fields[0][0];
            const matchValue_1 = filledNew.Result;
            return (matchValue_1.tag === 1) ? (new FilledForm$2(fields, new FSharpResult$2(1, [[firstError, append_1(otherErrors, cons(matchValue_1.fields[0][0], matchValue_1.fields[0][1]))]]), isEmpty)) : (new FilledForm$2(fields, new FSharpResult$2(1, [[firstError, otherErrors]]), isEmpty));
        }
        else {
            return new FilledForm$2(fields, Result_Map(matchValue.fields[0], filledNew.Result), isEmpty);
        }
    });
}

/**
 * Fill a form <c>andThen</c> fill another one.
 * 
 * This type of form is useful when some part of your form can dynamically change based on the value of another field.
 */
export function andThen(child, parent) {
    return new Form$3((values) => {
        const filled = fill(parent)(values);
        const matchValue = filled.Result;
        if (matchValue.tag === 1) {
            return new FilledForm$2(filled.Fields, new FSharpResult$2(1, [matchValue.fields[0]]), filled.IsEmpty);
        }
        else {
            const childFilled = fill(child(matchValue.fields[0]))(values);
            return new FilledForm$2(append_1(filled.Fields, childFilled.Fields), childFilled.Result, filled.IsEmpty && childFilled.IsEmpty);
        }
    });
}

/**
 * Disable a form
 * 
 * You can combine this with meta to disable parts of a form based on its own values.
 */
export function disable(form) {
    return new Form$3((values) => {
        const filled = fill(form)(values);
        return new FilledForm$2(map_1((filledField) => (new FilledField$1(filledField.State, filledField.Error, true)), filled.Fields), filled.Result, filled.IsEmpty);
    });
}

/**
 * Transform the 'output' of a form
 * 
 * You can use it to keep your forms decoupled from your specific view messages:
 * 
 * <code lang="fsharp">
 * Base.map SignUp signupForm
 * </code>
 */
export function map(fn, form) {
    return new Form$3((values) => {
        const filled = fill(form)(values);
        return new FilledForm$2(filled.Fields, Result_Map(fn, filled.Result), filled.IsEmpty);
    });
}

/**
 * Create function which is used to created a single form field
 * 
 * This functions is meant to be used when you want to design your own View layer.
 * 
 * See how it is use for Fable.Form.Simple <a href="https://github.com/MangelMaxime/Fable.Form/blob/91c70b9504706fd3d65fd0bbcad97d865b18284a/packages/Fable.Form.Simple/TextField.fs#L15-L17">TextField</a>
 */
export function field(isEmpty, build, config) {
    return new Form$3((values_2) => {
        let value, values_1;
        let result_2;
        const values = values_2;
        result_2 = Result_andThen((output) => defaultArg(map_2((error_1) => (new FSharpResult$2(1, [[new Error$_1(2, [error_1]), empty()]])), config.Error(values)), new FSharpResult$2(0, [output])), (value = config.Value(values), isEmpty(value) ? (new FSharpResult$2(1, [[new Error$_1(0, []), empty()]])) : Result_MapError((error) => [new Error$_1(1, [error]), empty()], config.Parser(value))));
        let patternInput;
        if (result_2.tag === 1) {
            const firstError = result_2.fields[0][0];
            patternInput = [firstError, equals(firstError, new Error$_1(0, []))];
        }
        else {
            patternInput = [void 0, false];
        }
        return new FilledForm$2(singleton(new FilledField$1((values_1 = values_2, build(new Field$3(config.Value(values_1), (newValue) => config.Update(newValue, values_1), config.Attributes))), patternInput[0], false)), result_2, patternInput[1]);
    });
}

/**
 * Make a form optional.
 * 
 * An optional form succeeds when:
 * - All of its fields are empty, in this case it returns <c>None</c>
 * - All of its fields are correct, in this case it returns <c>Some 'Output</c>
 */
export function optional(form) {
    return new Form$3((values) => {
        const filled = fill(form)(values);
        const matchValue = filled.Result;
        return (matchValue.tag === 1) ? (filled.IsEmpty ? (new FilledForm$2(map_1((field_1) => (new FilledField$1(field_1.State, void 0, field_1.IsDisabled)), filled.Fields), new FSharpResult$2(0, [void 0]), filled.IsEmpty)) : (new FilledForm$2(filled.Fields, new FSharpResult$2(1, [[matchValue.fields[0][0], matchValue.fields[0][1]]]), false))) : (new FilledForm$2(filled.Fields, new FSharpResult$2(0, [some(matchValue.fields[0])]), filled.IsEmpty));
    });
}

