import { Union, Record } from "../../fable_modules/fable-library.4.1.4/Types.js";
import { union_type, int64_type, string_type, option_type, bool_type, record_type, lambda_type, class_type, list_type, tuple_type, int32_type } from "../../fable_modules/fable-library.4.1.4/Reflection.js";
import { PagingOptionsModule_optionsForNextPage, PagingOptions, PagingOptionsModule_defaultPagingOptions, PagingOptionsModule_basicPagingOptions, EventInfo_$reflection, PagingOptions_$reflection } from "../../Omnicv.Shared.Common/CoreEventInfo.js";
import { Router_newUrl, Route_$reflection } from "../Router.js";
import { length, append, singleton, empty } from "../../fable_modules/fable-library.4.1.4/List.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../fable_modules/Fable.Elmish.4.0.2/cmd.fs.js";
import { curry2 } from "../../fable_modules/fable-library.4.1.4/Util.js";
import { commonAuthService } from "../CommonServer.js";
import { Session__GetCurrentProfileId } from "../SharedView.js";
import { exnToDisplayString } from "../Utils.js";

export class Actions$1 extends Record {
    constructor(GetRecords) {
        super();
        this.GetRecords = GetRecords;
    }
}

export function Actions$1_$reflection(gen0) {
    return record_type("Omnicv.Client.Components.ViewRecords.Actions`1", [gen0], Actions$1, () => [["GetRecords", lambda_type(int32_type, lambda_type(PagingOptions_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [list_type(tuple_type(EventInfo_$reflection(), gen0))])))]]);
}

export class Model$1 extends Record {
    constructor(PageSize, Ascending, DateRange, LastFetchSize, Records, Actions, Error$, InProgress, LastPagingOptions) {
        super();
        this.PageSize = (PageSize | 0);
        this.Ascending = Ascending;
        this.DateRange = DateRange;
        this.LastFetchSize = LastFetchSize;
        this.Records = Records;
        this.Actions = Actions;
        this.Error = Error$;
        this.InProgress = InProgress;
        this.LastPagingOptions = LastPagingOptions;
    }
}

export function Model$1_$reflection(gen0) {
    return record_type("Omnicv.Client.Components.ViewRecords.Model`1", [gen0], Model$1, () => [["PageSize", int32_type], ["Ascending", bool_type], ["DateRange", option_type(tuple_type(class_type("System.DateTime"), class_type("System.DateTime")))], ["LastFetchSize", option_type(int32_type)], ["Records", list_type(tuple_type(EventInfo_$reflection(), gen0))], ["Actions", Actions$1_$reflection(gen0)], ["Error", option_type(string_type)], ["InProgress", bool_type], ["LastPagingOptions", option_type(PagingOptions_$reflection())]]);
}

export function Model$1__CanFetchMore(this$) {
    const matchValue = this$.LastFetchSize;
    if (matchValue != null) {
        return this$.PageSize <= matchValue;
    }
    else {
        return true;
    }
}

export class Msg$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RequestFetch", "RequestReload", "UpdateDateRange", "FetchSucceeded", "FetchFailed", "Delete", "DeleteSucceeded", "NavigateTo"];
    }
}

export function Msg$1_$reflection(gen0) {
    return union_type("Omnicv.Client.Components.ViewRecords.Msg`1", [gen0], Msg$1, () => [[], [], [["Item1", class_type("System.DateTime")], ["Item2", class_type("System.DateTime")]], [["Item", list_type(tuple_type(EventInfo_$reflection(), gen0))]], [["Item", class_type("System.Exception")]], [["Item", int64_type]], [["Item", string_type]], [["Item", Route_$reflection()]]]);
}

function initCommon(actions, pageSize, ascending, dateRange) {
    return [new Model$1(pageSize, ascending, dateRange, void 0, empty(), actions, void 0, false, void 0), singleton((dispatch) => {
        dispatch(new Msg$1(0, []));
    })];
}

export function init(actions, pageSize, ascending) {
    return initCommon(actions, pageSize, ascending, void 0);
}

export function initDateRange(actions, ascending, dateRange_, dateRange__1) {
    return initCommon(actions, 150, ascending, [dateRange_, dateRange__1]);
}

export function update(session, msg, model) {
    switch (msg.tag) {
        case 3: {
            const result = msg.fields[0];
            let newModel;
            const Records = append(model.Records, result);
            newModel = (new Model$1(model.PageSize, model.Ascending, model.DateRange, length(result), Records, model.Actions, model.Error, false, model.LastPagingOptions));
            if ((model.DateRange != null) && Model$1__CanFetchMore(newModel)) {
                return [new Model$1(newModel.PageSize, newModel.Ascending, newModel.DateRange, newModel.LastFetchSize, newModel.Records, newModel.Actions, newModel.Error, true, newModel.LastPagingOptions), singleton((dispatch) => {
                    dispatch(new Msg$1(0, []));
                })];
            }
            else {
                return [newModel, Cmd_none()];
            }
        }
        case 1:
            return [new Model$1(model.PageSize, model.Ascending, model.DateRange, void 0, empty(), model.Actions, model.Error, true, void 0), singleton((dispatch_1) => {
                dispatch_1(new Msg$1(0, []));
            })];
        case 2:
            return [new Model$1(model.PageSize, model.Ascending, [msg.fields[0], msg.fields[1]], void 0, empty(), model.Actions, model.Error, true, void 0), singleton((dispatch_2) => {
                dispatch_2(new Msg$1(0, []));
            })];
        case 5:
            return [model, Cmd_OfAsyncWith_either((x_1) => {
                Cmd_OfAsync_start(x_1);
            }, curry2(commonAuthService.DeleteRecord)(Session__GetCurrentProfileId(session)), msg.fields[0], (arg_3) => (new Msg$1(6, [arg_3])), (arg_4) => (new Msg$1(4, [arg_4])))];
        case 6:
            return [new Model$1(model.PageSize, model.Ascending, model.DateRange, model.LastFetchSize, empty(), model.Actions, model.Error, model.InProgress, model.LastPagingOptions), singleton((dispatch_3) => {
                dispatch_3(new Msg$1(0, []));
            })];
        case 4:
            return [new Model$1(model.PageSize, model.Ascending, model.DateRange, model.LastFetchSize, model.Records, model.Actions, exnToDisplayString(msg.fields[0]), false, model.LastPagingOptions), Cmd_none()];
        case 7:
            return [model, Router_newUrl(msg.fields[0])];
        default: {
            let pagingOptions;
            const matchValue = model.LastPagingOptions;
            if (matchValue == null) {
                let basePagingOptions;
                const matchValue_1 = model.DateRange;
                basePagingOptions = ((matchValue_1 != null) ? PagingOptionsModule_basicPagingOptions(matchValue_1[0], matchValue_1[1]) : PagingOptionsModule_defaultPagingOptions);
                pagingOptions = (new PagingOptions(basePagingOptions.LastBoundary, model.PageSize, model.Ascending, basePagingOptions.From, basePagingOptions.To));
            }
            else {
                pagingOptions = PagingOptionsModule_optionsForNextPage(model.Records, matchValue);
            }
            return [new Model$1(model.PageSize, model.Ascending, model.DateRange, model.LastFetchSize, model.Records, model.Actions, model.Error, true, pagingOptions), Cmd_OfAsyncWith_either((x) => {
                Cmd_OfAsync_start(x);
            }, curry2(model.Actions.GetRecords)(Session__GetCurrentProfileId(session)), pagingOptions, (arg) => (new Msg$1(3, [arg])), (arg_1) => (new Msg$1(4, [arg_1])))];
        }
    }
}

