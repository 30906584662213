import { Union } from "../../../fable-library.4.1.4/Types.js";
import { Common_GenericOptions__AddModifiers_Z7C3AFA3, Common_GenericOptions__AddProps_Z4E55E0BD, Common_GenericOptions__AddCaseName_1505, Color_ofColor, Common_GenericOptions__AddClass_Z721C83C5, Common_GenericOptions_Parse_Z4D1A7726, Common_GenericOptions__ToReactElement_4509C2D7, Modifier_IModifier_$reflection, Color_IColor_$reflection, Size_ISize_$reflection } from "../../Common.fs.js";
import { union_type, string_type, list_type, class_type, bool_type } from "../../../fable-library.4.1.4/Reflection.js";
import * as react from "react";
import { keyValueList } from "../../../fable-library.4.1.4/MapUtil.js";
import { Reflection_getCaseName } from "../../Common.fs.js";

export class Option extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Size", "is-fullwidth", "is-inline", "is-loading", "is-focused", "is-active", "Disabled", "Color", "is-rounded", "is-multiple", "Props", "CustomClass", "Modifiers"];
    }
}

export function Option_$reflection() {
    return union_type("Fulma.Select.Option", [], Option, () => [[["Item", Size_ISize_$reflection()]], [], [], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", Color_IColor_$reflection()]], [], [], [["Item", list_type(class_type("Fable.React.IHTMLProp"))]], [["Item", string_type]], [["Item", list_type(Modifier_IModifier_$reflection())]]]);
}

/**
 * Generate <div class="select"></div>
 */
export function select(options, children) {
    return Common_GenericOptions__ToReactElement_4509C2D7(Common_GenericOptions_Parse_Z4D1A7726(options, (result, option) => {
        let matchResult, state;
        switch (option.tag) {
            case 7: {
                matchResult = 1;
                break;
            }
            case 1:
            case 2:
            case 9:
            case 8: {
                matchResult = 2;
                break;
            }
            case 10: {
                matchResult = 4;
                break;
            }
            case 11: {
                matchResult = 5;
                break;
            }
            case 12: {
                matchResult = 6;
                break;
            }
            case 3: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            case 4: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            case 5: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            case 6: {
                matchResult = 3;
                state = option.fields[0];
                break;
            }
            default:
                matchResult = 0;
        }
        switch (matchResult) {
            case 0:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Reflection_getCaseName(option.fields[0]));
            case 1:
                return Common_GenericOptions__AddClass_Z721C83C5(result, Color_ofColor(option.fields[0]));
            case 2:
                return Common_GenericOptions__AddCaseName_1505(result, option);
            case 3:
                if (state) {
                    return Common_GenericOptions__AddCaseName_1505(result, option);
                }
                else {
                    return result;
                }
            case 4:
                return Common_GenericOptions__AddProps_Z4E55E0BD(result, option.fields[0]);
            case 5:
                return Common_GenericOptions__AddClass_Z721C83C5(result, option.fields[0]);
            default:
                return Common_GenericOptions__AddModifiers_Z7C3AFA3(result, option.fields[0]);
        }
    }, "select"), (props_1, children_1) => react.createElement("div", keyValueList(props_1, 1), ...children_1), children);
}

