import { Constraints_noneOrSomeTogether, Constraints_minMaxOptLengthConstraint, Constraints_minMaxLengthConstraint, Constraints_minMaxConstraint, Constraints_collectConstraintsErrors, Constraints_applyConstraintsGetErrors, Constraints_maxLengthConstraint, Constraints_minLengthConstraint, Constraints_notEmptyOrWhitespaceConstraint } from "../../Omnicv.Shared.Common/Validation.js";
import { length, singleton, ofArray } from "../../fable_modules/fable-library.4.1.4/List.js";
import { ofList, tryFind } from "../../fable_modules/fable-library.4.1.4/Map.js";
import { normaliseTags, splitServerTags, validateServerTags } from "../../Omnicv.Shared.Common/TagUtils.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library.4.1.4/Choice.js";
import { List_distinctBy } from "../../fable_modules/fable-library.4.1.4/Seq2.js";
import { comparePrimitives, stringHash } from "../../fable_modules/fable-library.4.1.4/Util.js";
import { Record } from "../../fable_modules/fable-library.4.1.4/Types.js";
import { bool_type, option_type, class_type, enum_type, int32_type, uint8_type, record_type, string_type, int64_type } from "../../fable_modules/fable-library.4.1.4/Reflection.js";

export const taglineName = "Tagline";

const taglineConstraints = ofArray([(name) => ((value) => Constraints_notEmptyOrWhitespaceConstraint(name, value)), (name_1) => ((value_1) => Constraints_minLengthConstraint(1, name_1, value_1)), (name_2) => ((value_2) => Constraints_maxLengthConstraint(1024, name_2, value_2))]);

function getTaglineErrors(tagline) {
    return Constraints_applyConstraintsGetErrors(taglineConstraints, taglineName, tagline);
}

export function validateTagline(notes) {
    return tryFind(taglineName, Constraints_collectConstraintsErrors(singleton(getTaglineErrors(notes))));
}

const notesConstraints = ofArray([(name) => ((value) => Constraints_notEmptyOrWhitespaceConstraint(name, value)), (name_1) => ((value_1) => Constraints_minLengthConstraint(10, name_1, value_1)), (name_2) => ((value_2) => Constraints_maxLengthConstraint(100000, name_2, value_2))]);

export const notesName = "Notes";

function getNotesErrors(notes) {
    return Constraints_applyConstraintsGetErrors(notesConstraints, notesName, notes);
}

export function validateNotes(notes) {
    return tryFind(notesName, Constraints_collectConstraintsErrors(singleton(getNotesErrors(notes))));
}

function getTagsErrors(tags) {
    return Constraints_applyConstraintsGetErrors(ofArray([(name_3) => ((value) => Constraints_maxLengthConstraint(512, name_3, value)), (name_1) => ((tags_1) => {
        const matchValue = validateServerTags(tags_1);
        return (matchValue == null) ? (new FSharpResult$2(0, [tags_1])) : (new FSharpResult$2(1, [[name_1, matchValue]]));
    }), (name_2) => ((tags_2) => {
        const tagsList = splitServerTags(tags_2);
        const distinctCount = length(List_distinctBy((s) => s.toLocaleLowerCase(), tagsList, {
            Equals: (x, y) => (x === y),
            GetHashCode: stringHash,
        })) | 0;
        return (length(tagsList) !== distinctCount) ? (new FSharpResult$2(1, [[name_2, "Must not contain duplicates"]])) : (new FSharpResult$2(0, [tags_2]));
    })]), "Tags", tags);
}

export const tagsName = "Tags";

export function validateTags(tags) {
    return tryFind(tagsName, Constraints_collectConstraintsErrors(singleton(getTagsErrors(tags))));
}

function getRatingErrors(rating) {
    let minValue, maxValue;
    return Constraints_applyConstraintsGetErrors(singleton((minValue = (1 & 0xFF), (maxValue = (10 & 0xFF), (name_1) => ((value) => Constraints_minMaxConstraint(minValue, maxValue, name_1, value))))), "Rating", rating);
}

export const ratingName = "Rating";

export function validateRating(rating) {
    return tryFind(ratingName, Constraints_collectConstraintsErrors(singleton(getRatingErrors(rating))));
}

const InteractionRecordModule_participantsConstraints = singleton((name) => ((value) => Constraints_minMaxLengthConstraint(1, 1000, name, value)));

export const InteractionRecordModule_participantsName = "Participants";

export function InteractionRecordModule_getParticipantsErrors(participants) {
    return Constraints_applyConstraintsGetErrors(InteractionRecordModule_participantsConstraints, InteractionRecordModule_participantsName, participants);
}

export function InteractionRecordModule_validateParticipants(participants) {
    return tryFind(InteractionRecordModule_participantsName, Constraints_collectConstraintsErrors(singleton(InteractionRecordModule_getParticipantsErrors(participants))));
}

const InteractionRecordModule_followupNotesConstraints = singleton((name) => ((value) => Constraints_minMaxOptLengthConstraint(1, 1000, name, value)));

export const InteractionRecordModule_followupNotesName = "FollowupNotes";

export function InteractionRecordModule_getFollowupNotesErrors(followupNotes) {
    return Constraints_applyConstraintsGetErrors(InteractionRecordModule_followupNotesConstraints, InteractionRecordModule_followupNotesName, followupNotes);
}

export function InteractionRecordModule_validateFollowupNotes(followupNotes) {
    return tryFind(InteractionRecordModule_followupNotesName, Constraints_collectConstraintsErrors(singleton(InteractionRecordModule_getFollowupNotesErrors(followupNotes))));
}

export class DiaryRecord extends Record {
    constructor(Id, Tagline, Notes, Tags) {
        super();
        this.Id = Id;
        this.Tagline = Tagline;
        this.Notes = Notes;
        this.Tags = Tags;
    }
}

export function DiaryRecord_$reflection() {
    return record_type("Omnicv.Diary.Shared.Data.DiaryRecord", [], DiaryRecord, () => [["Id", int64_type], ["Tagline", string_type], ["Notes", string_type], ["Tags", string_type]]);
}

function DiaryRecord_Validator_6A4ED0A1(record) {
    return Constraints_collectConstraintsErrors(ofArray([getTaglineErrors(record.Tagline), getNotesErrors(record.Notes), getTagsErrors(record.Tags)]));
}

export function DiaryRecord_Default() {
    return new DiaryRecord(0n, "", "", "");
}

export function DiaryRecord__GetTags(this$) {
    return normaliseTags(this$.Tags);
}

export function DiaryRecord__Validate(this$) {
    return DiaryRecord_Validator_6A4ED0A1(this$);
}

export function DiaryRecord__ValidateField_Z721C83C5(this$, name) {
    return tryFind(name, DiaryRecord__Validate(this$));
}

export class ReviewRecord extends Record {
    constructor(Id, Tagline, Notes, Tags, Rating) {
        super();
        this.Id = Id;
        this.Tagline = Tagline;
        this.Notes = Notes;
        this.Tags = Tags;
        this.Rating = Rating;
    }
}

export function ReviewRecord_$reflection() {
    return record_type("Omnicv.Diary.Shared.Data.ReviewRecord", [], ReviewRecord, () => [["Id", int64_type], ["Tagline", string_type], ["Notes", string_type], ["Tags", string_type], ["Rating", uint8_type]]);
}

function ReviewRecord_Validator_Z14ABCC84(record) {
    return Constraints_collectConstraintsErrors(ofArray([getTaglineErrors(record.Tagline), getNotesErrors(record.Notes), getTagsErrors(record.Tags), getRatingErrors(record.Rating)]));
}

export function ReviewRecord_Default() {
    return new ReviewRecord(0n, "", "", "", 0 & 0xFF);
}

export function ReviewRecord__GetTags(this$) {
    return normaliseTags(this$.Tags);
}

export function ReviewRecord__Validate(this$) {
    return ReviewRecord_Validator_Z14ABCC84(this$);
}

export function ReviewRecord__ValidateField_Z721C83C5(this$, name) {
    return tryFind(name, ReviewRecord__Validate(this$));
}

export const interactionTypes = ofArray([[0, "Other"], [1, "Physical"], [2, "Call"], [3, "Video call"], [4, "Text message"], [5, "E-mail"], [6, "Postal"]]);

export class InteractionRecord extends Record {
    constructor(Id, Tagline, Participants, DurationSec, InteractionType, Notes, FollowupTime, FollowupNotes, FollowupDismissed, Tags) {
        super();
        this.Id = Id;
        this.Tagline = Tagline;
        this.Participants = Participants;
        this.DurationSec = (DurationSec | 0);
        this.InteractionType = (InteractionType | 0);
        this.Notes = Notes;
        this.FollowupTime = FollowupTime;
        this.FollowupNotes = FollowupNotes;
        this.FollowupDismissed = FollowupDismissed;
        this.Tags = Tags;
    }
}

export function InteractionRecord_$reflection() {
    return record_type("Omnicv.Diary.Shared.Data.InteractionRecord", [], InteractionRecord, () => [["Id", int64_type], ["Tagline", string_type], ["Participants", string_type], ["DurationSec", int32_type], ["InteractionType", enum_type("Omnicv.Diary.Shared.Data.InteractionType", int32_type, [["Other", 0], ["Physical", 1], ["Call", 2], ["VideoCall", 3], ["TextMessage", 4], ["Email", 5], ["Postal", 6]])], ["Notes", string_type], ["FollowupTime", option_type(class_type("System.DateTime"))], ["FollowupNotes", option_type(string_type)], ["FollowupDismissed", option_type(bool_type)], ["Tags", string_type]]);
}

function InteractionRecord_ValidateFollowup_1BC6EC3C(record) {
    return Constraints_applyConstraintsGetErrors(singleton((name) => ((mapping) => Constraints_noneOrSomeTogether(name, mapping))), "Followup", ofList(ofArray([["FollowupTime", record.FollowupTime != null], ["FollowupNotes", record.FollowupNotes != null]]), {
        Compare: comparePrimitives,
    }));
}

function InteractionRecord_Validator_1BC6EC3C(record) {
    return Constraints_collectConstraintsErrors(ofArray([getTaglineErrors(record.Tagline), getNotesErrors(record.Notes), getTagsErrors(record.Tags), InteractionRecordModule_getParticipantsErrors(record.Participants), InteractionRecordModule_getFollowupNotesErrors(record.FollowupNotes), InteractionRecord_ValidateFollowup_1BC6EC3C(record)]));
}

export function InteractionRecord_Default() {
    return new InteractionRecord(0n, "", "", 0, 0, "", void 0, void 0, void 0, "");
}

export function InteractionRecord__GetTags(this$) {
    return normaliseTags(this$.Tags);
}

export function InteractionRecord__Validate(this$) {
    return InteractionRecord_Validator_1BC6EC3C(this$);
}

export function InteractionRecord__ValidateField_Z721C83C5(this$, name) {
    return tryFind(name, InteractionRecord__Validate(this$));
}

export class ReminderRecord extends Record {
    constructor(Id, Tagline, EventTimestamp, FollowupTime, FollowupNotes, FollowupDismissed) {
        super();
        this.Id = Id;
        this.Tagline = Tagline;
        this.EventTimestamp = EventTimestamp;
        this.FollowupTime = FollowupTime;
        this.FollowupNotes = FollowupNotes;
        this.FollowupDismissed = FollowupDismissed;
    }
}

export function ReminderRecord_$reflection() {
    return record_type("Omnicv.Diary.Shared.Data.ReminderRecord", [], ReminderRecord, () => [["Id", int64_type], ["Tagline", string_type], ["EventTimestamp", class_type("System.DateTime")], ["FollowupTime", option_type(class_type("System.DateTime"))], ["FollowupNotes", option_type(string_type)], ["FollowupDismissed", option_type(bool_type)]]);
}

