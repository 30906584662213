import * as common from "../../src/javascript/common.js";
import { value } from "../fable_modules/fable-library.4.1.4/Option.js";
import { Record } from "../fable_modules/fable-library.4.1.4/Types.js";
import { record_type, option_type, float64_type, class_type, string_type } from "../fable_modules/fable-library.4.1.4/Reflection.js";

export function createLatLng(lat, lng) {
    return {
        lat: lat,
        lng: lng,
    };
}

export function createLatLngBounds(north, east, south, west) {
    return {
        north: north,
        east: east,
        south: south,
        west: west,
    };
}

export function createCircleLiteral(center, radius) {
    return {
        center: center,
        radius: radius,
    };
}

export function createCircle(center, radius) {
    const circle = common.createCircle();
    circle.setRadius(radius);
    circle.setCenter(center);
    return circle;
}

export function createCoords(lat, lng) {
    return {
        accuracy: 10,
        heading: 0,
        latitude: lat,
        longitude: lng,
        speed: 0,
    };
}

export function createPosition(lat, lng) {
    return {
        coords: createCoords(lat, lng),
        timestamp: 0,
    };
}

export function toPosition(lat, lng) {
    let matchResult, latValue, lngValue;
    if (lat != null) {
        if (lng != null) {
            matchResult = 0;
            latValue = value(lat);
            lngValue = value(lng);
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return createPosition(latValue, lngValue);
        default:
            return void 0;
    }
}

export class LocationDescriptor extends Record {
    constructor(PlaceId, Name, Position, DistanceFromCurrent) {
        super();
        this.PlaceId = PlaceId;
        this.Name = Name;
        this.Position = Position;
        this.DistanceFromCurrent = DistanceFromCurrent;
    }
}

export function LocationDescriptor_$reflection() {
    return record_type("Omnicv.Client.MapLiteral.LocationDescriptor", [], LocationDescriptor, () => [["PlaceId", string_type], ["Name", string_type], ["Position", class_type("Browser.Types.Position")], ["DistanceFromCurrent", option_type(float64_type)]]);
}

const earthRadius = 6371071;

export function distanceInMeters(lat1, long1, lat2, long2) {
    const rLat1 = lat1 * (3.141592653589793 / 180);
    const rLat2 = lat2 * (3.141592653589793 / 180);
    const diffLat = rLat2 - rLat1;
    const diffLon = (long2 - long1) * (3.141592653589793 / 180);
    return (2 * earthRadius) * Math.asin(Math.sqrt((Math.sin(diffLat / 2) * Math.sin(diffLat / 2)) + (((Math.cos(rLat1) * Math.cos(rLat2)) * Math.sin(diffLon / 2)) * Math.sin(diffLon / 2))));
}

export function distanceInMetersBetweenPositions(pos1, pos2) {
    return distanceInMeters(pos1.coords.latitude, pos1.coords.longitude, pos2.coords.latitude, pos2.coords.longitude);
}

