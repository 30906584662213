import { Fa_IconOption } from "../../fable_modules/Fable.FontAwesome.3.0.0/FontAwesome.fs.js";
import { EntryDescriptor, Actions, ModuleDescriptor, IconType } from "../../Omnicv.Client.Common/ClientModule.js";
import { Route } from "../../Omnicv.Client.Common/Router.js";
import { ofArray } from "../../fable_modules/fable-library.4.1.4/List.js";
import { DiaryModule_$reflection, DiaryModule } from "../Omnicv.Diary.Shared/DiaryAPI.js";
import { class_type } from "../../fable_modules/fable-library.4.1.4/Reflection.js";

export class DiaryClientModule extends DiaryModule {
    constructor() {
        super();
    }
    Descriptor() {
        return new ModuleDescriptor(new IconType(new Fa_IconOption(11, ["fas fa-journal-whills"])), (arg) => (new Route(9, [arg])));
    }
    EntryDescriptors() {
        return ofArray([new EntryDescriptor("DiaryRecord", "Diary", "Diary entries", new IconType(new Fa_IconOption(11, ["fas fa-journal-whills"])), new Actions((arg) => (new Route(10, [arg])), (arg_1) => (new Route(11, [arg_1])), void 0, void 0)), new EntryDescriptor("ReviewRecord", "Review", "Review your experiences", new IconType(new Fa_IconOption(11, ["fas fa-star-half-alt"])), new Actions((arg_2) => (new Route(12, [arg_2])), (arg_3) => (new Route(13, [arg_3])), void 0, void 0)), new EntryDescriptor("InteractionRecord", "Interaction", "Track your interactions with others", new IconType(new Fa_IconOption(11, ["fas fa-phone-alt"])), new Actions((arg_4) => (new Route(14, [arg_4])), (arg_5) => (new Route(15, [arg_5])), void 0, void 0))]);
    }
}

export function DiaryClientModule_$reflection() {
    return class_type("Omnicv.Client.Diary.DiaryClientModule", void 0, DiaryClientModule, DiaryModule_$reflection());
}

export function DiaryClientModule_$ctor() {
    return new DiaryClientModule();
}

