import { Union } from "../../fable_modules/fable-library.4.1.4/Types.js";
import { union_type, lambda_type, unit_type, float64_type, bool_type, int32_type, string_type } from "../../fable_modules/fable-library.4.1.4/Reflection.js";
import { keyValueList } from "../../fable_modules/fable-library.4.1.4/MapUtil.js";
import * as react from "react";
import react_rating_stars_component from "react-rating-stars-component";

export class RatingStarsProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ActiveColor", "Count", "Edit", "IsHalf", "Size", "Value", "OnChange"];
    }
}

export function RatingStarsProps_$reflection() {
    return union_type("Omnicv.Client.Components.RatingStars.RatingStarsProps", [], RatingStarsProps, () => [[["Item", string_type]], [["Item", int32_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", int32_type]], [["Item", float64_type]], [["Item", lambda_type(float64_type, unit_type)]]]);
}

export function ratingStars(props) {
    const props_1 = keyValueList(props, 1);
    return react.createElement(react_rating_stars_component, props_1);
}

